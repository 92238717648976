import React from "react";
import Text from "../Text";
import TextF5 from "../TextF5";
import DefaultContainer from "./DefaultContainer";
import styled from "styled-components";

const StyledTextF5 = styled(TextF5)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
const MarkdownH3 = props => {
  const { ...rest } = props;
  return (
    <DefaultContainer>
      <StyledTextF5 {...rest} />
    </DefaultContainer>
  );
};

export default MarkdownH3;
