import fetchPonyfill from "fetch-ponyfill";
const { fetch } = fetchPonyfill();

export const submitFeedback = (
  message = null,
  chapter = null,
  email = null
) => {
  fetch("https://hooks.zapier.com/hooks/catch/124325/aod0gt/", {
    body: JSON.stringify({
      data: {
        message,
        chapter,
        email
      }
    }),
    method: "POST"
  })
    .then(res => {
      // alert('message sent')
    })
    .catch(error => console.error(error));
};
