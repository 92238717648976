import React from "react";
import * as d3 from "d3";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import {
  GraphConstants,
  GraphFunctions,
  AxisLine,
  AxisText,
  AxisLabel,
  Curve,
  DataPoint,
  GridLine
} from "./GraphBase";

const width = 600;
const height = 400;
const margin = 50;

const ColorLabel = styled.text`
  font-size: ${GraphConstants.axisTextSize};
  fill: ${GraphConstants.axisTextFill};
  font-family: ${GraphConstants.axistFontFamily};
  opacity: ${GraphConstants.axistLabelOpacity};
`;

const LogisticRegressionChart = ({
  data,
  minX,
  maxX,
  beta,
  alpha,
  disableIncorrect,
  xLabel,
  yLabel
}) => {
  const canvasWidth = width - 2 * margin;
  const canvasHeight = height - 2 * margin;
  const xScale = d3
    .scaleLinear()
    .domain([minX, maxX])
    .range([0, canvasWidth]);
  const yScale = d3
    .scaleLinear()
    .domain([0, 1])
    .range([canvasHeight, 0]);

  const pathData = d3
    .range(minX, maxX, 0.05)
    .map(x => ({ x, y: GraphFunctions.sigmoid(x, alpha, beta) }))
    .map(d => [xScale(d.x), yScale(d.y)]);
  const lineGenerator = d3.line();
  const bottomAxisValues = xScale.ticks(7);
  const leftAxisValues = yScale.ticks(10);
  const validateClassification = d =>
    Math.round(GraphFunctions.sigmoid(d.x, alpha, beta)) === d.y;
  const formatPercent = d3.format(".0%");

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      {disableIncorrect ? (
        <g transform={`translate(${margin}, ${0})`}>
          <DataPoint
            disableIncorrect={true}
            correct={true}
            cx={0}
            cy={10}
            r={GraphConstants.dataPointRadius}
          />
          <DataPoint
            disableIncorrect={true}
            correct={false}
            cx={0}
            cy={25}
            r={GraphConstants.dataPointRadius}
          />
          <ColorLabel x={15} y={14}>
            <FormattedMessage id="visualizations.logisticRegressionChart.classifiedCorrectly" />
          </ColorLabel>
          <ColorLabel x={15} y={29}>
            <FormattedMessage id="visualizations.logisticRegressionChart.notClassifiedCorrectly" />
          </ColorLabel>
        </g>
      ) : (
        ""
      )}
      <g transform={`translate(${margin}, ${height})`}>
        {bottomAxisValues.map((value, index) => (
          <AxisText key={index} x={xScale(value)} y={-margin + 18}>
            {value}
          </AxisText>
        ))}
      </g>
      <g transform={`translate(0, ${margin})`}>
        {leftAxisValues.map((value, index) => (
          <AxisText key={index} x={margin - 15} y={yScale(value)}>
            {formatPercent(value)}
          </AxisText>
        ))}
      </g>
      <g transform={`translate(${margin}, ${margin})`}>
        {bottomAxisValues.map((value, index) => (
          <GridLine
            key={index}
            x1={xScale(value)}
            x2={xScale(value)}
            y1={0}
            y2={canvasHeight}
          />
        ))}
      </g>
      <g transform={`translate(${margin}, ${margin})`}>
        {leftAxisValues.map((value, index) => (
          <GridLine
            key={index}
            x1={0}
            x2={canvasWidth}
            y1={yScale(value)}
            y2={yScale(value)}
          />
        ))}
      </g>
      <AxisLine
        y1={height - margin}
        y2={height - margin}
        x1={margin}
        x2={width - margin}
      />
      <AxisLine y1={margin} y2={height - margin} x1={margin} x2={margin} />
      <AxisLabel transform={"rotate(-90)"} x={-height / 2} y={13}>
        {yLabel}
      </AxisLabel>
      <AxisLabel x={width / 2} y={height - 15}>
        {xLabel}
      </AxisLabel>
      <g transform={`translate(${margin}, ${margin})`}>
        <Curve d={lineGenerator(pathData)} />
      </g>
      <g transform={`translate(${margin}, ${margin})`}>
        {data.map((d, index) => (
          <DataPoint
            key={index}
            disableIncorrect={disableIncorrect}
            correct={validateClassification(d)}
            cx={xScale(d.x)}
            cy={yScale(d.y)}
            r={GraphConstants.dataPointRadius}
          />
        ))}
      </g>
    </svg>
  );
};

export default LogisticRegressionChart;
