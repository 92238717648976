import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import Box from "../Box";

import TextF1 from "../TextF1";
import TextF2 from "../TextF2";
import TextF3 from "../TextF3";
import TextF5 from "../TextF5";
import TextF6 from "../TextF6";
import TextF7 from "../TextF7";

import DefaultContainer from "./DefaultContainer";

import reason1Url from "../../images/1_1_reason-1.svg";
import reason2Url from "../../images/1_1_reason-2.svg";
import reason3Url from "../../images/1_1_reason-3.svg";
import reason4Url from "../../images/1_1_reason-4.svg";

const RichText = styled(TextF7)`
  a {
    color: #9c329c;
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledBox = styled(Box)`
  position: relative;
  @media screen and (min-width: 38em) {
    padding-left: 160px;
  }
  h3 {
    margin-top: 0;
    padding-left: 76px;
    min-height: 60px;
    @media screen and (min-width: 38em) {
      min-height: auto;
      padding-left: 0;
    }
  }
`;

const IconWrapper = styled(Box)`
  position: absolute;
  left: 0px;
  width: 60px;
  height: 60px;
  top: 0;
  img {
    width: 60px;
    height: 60px;
  }
  @media screen and (min-width: 38em) {
    width: 120px;
    height: 120px;
    top: 0;
    img {
      width: 120px;
      height: 120px;
    }
  }
`;

const Icon = props => {
  switch (props.motive) {
    case "reason1":
      return <img src={reason1Url} />;
    case "reason2":
      return <img src={reason2Url} />;
    case "reason3":
      return <img src={reason3Url} />;
    case "reason4":
      return <img src={reason4Url} />;
    default:
      return null;
  }
};

const ContentWithIcon = props => {
  const { heading, description, motive } = props;
  return (
    <DefaultContainer>
      <Box py={5} borderBottom="2px solid #ebe9ef">
        <StyledBox>
          <IconWrapper>
            <Icon motive={motive} />
          </IconWrapper>
          <div className="inner">
            <TextF5 mt={0} mb={3}>
              {heading}
            </TextF5>
            <RichText m={0} dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </StyledBox>
      </Box>
    </DefaultContainer>
  );
};

export default ContentWithIcon;
