import React, { Component } from "react";
import ScrollProgress from "./ScrollProgressPlugin";

export default class ScrollProgressLine extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      progressOpacity: 0
    };
  }

  componentDidMount() {
    this.progressObserver = new ScrollProgress((x, y) => {
      this.setState({ progress: y * 100 });
    });
    setTimeout(
      function() {
        this.progressObserver.update();
        this.setState({ progressOpacity: 1 });
      }.bind(this),
      1000
    );
  }

  componentWillUnmount() {
    this.progressObserver.destroy();
  }

  render() {
    const style = {
      backgroundColor: "#19CA94",
      height: "4px",
      position: "fixed",
      top: 0,
      bottom: 0,
      zIndex: 9,
      transition: "opacity 0.2s ease 0.3s",
      opacity: `${this.state.progressOpacity}`,
      width: `${this.state.progress}%`
    };

    return <div className="progress-bar" style={style} />;
  }
}
