import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { injectIntl } from "react-intl";
import FunctionChart from "../../components/Visualizations/FunctionChart";
import RegressionChart from "../../components/Visualizations/RegressionChart";
import { GraphFunctions } from "../../components/Visualizations/GraphBase";
import DefaultContainer from "./DefaultContainer";

const plotData = [
  { x: 4.2, y: 27.4 },
  { x: 7.2, y: 41.8 },
  { x: 0.0, y: 6.6 },
  { x: 3.0, y: 25.1 },
  { x: 1.5, y: 11.8 },
  { x: 0.9, y: 15.8 },
  { x: 1.9, y: 27.6 },
  { x: 3.5, y: 31.0 },
  { x: 4.0, y: 28.9 },
  { x: 5.4, y: 32.5 },
  { x: 4.2, y: 27.2 },
  { x: 6.9, y: 52.7 },
  { x: 2.0, y: 20.5 },
  { x: 8.8, y: 50.7 },
  { x: 0.3, y: 12.3 },
  { x: 6.7, y: 54.0 },
  { x: 4.2, y: 31.5 },
  { x: 5.6, y: 41.0 },
  { x: 1.4, y: 18.5 },
  { x: 2.0, y: 18.1 },
  { x: 8.0, y: 44.3 },
  { x: 9.7, y: 56.7 },
  { x: 3.1, y: 24.6 },
  { x: 6.9, y: 47.5 },
  { x: 8.8, y: 58.0 },
  { x: 8.9, y: 59.4 },
  { x: 0.9, y: 15.7 },
  { x: 0.4, y: 16.4 },
  { x: 1.7, y: 14.7 },
  { x: 8.8, y: 60.2 },
  { x: 1.0, y: 17.5 },
  { x: 4.2, y: 29.6 },
  { x: 9.6, y: 60.3 },
  { x: 5.3, y: 36.3 },
  { x: 6.9, y: 50.3 },
  { x: 3.2, y: 33.4 },
  { x: 6.9, y: 55.3 },
  { x: 8.3, y: 44.7 },
  { x: 0.2, y: 3.7 },
  { x: 7.5, y: 45.0 },
  { x: 9.9, y: 60.2 },
  { x: 7.5, y: 51.8 },
  { x: 2.8, y: 25.6 },
  { x: 7.9, y: 39.4 },
  { x: 1.0, y: 13.6 },
  { x: 4.5, y: 36.5 },
  { x: 9.1, y: 56.6 },
  { x: 2.9, y: 28.5 },
  { x: 2.9, y: 23.3 },
  { x: 1.3, y: 15.5 }
];

class LinearRegressionStatic extends React.Component {
  render() {
    return (
      <DefaultContainer>
        <FunctionChart
          xLabel={this.props.intl.formatMessage({
            id: "markdowncomponents.linearRegressionStatic.xLabel"
          })}
          yLabel={this.props.intl.formatMessage({
            id: "markdowncomponents.linearRegressionStatic.yLabel"
          })}
          data={plotData}
          maxX={10}
          minX={0}
          minY={0}
          maxY={60}
          chartFunction={x => GraphFunctions.linearregression(x, 5.136, 10.007)}
        />
      </DefaultContainer>
    );
  }
}

export default injectIntl(LinearRegressionStatic);
