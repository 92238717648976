import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { themeGet } from "styled-system";
import Box from "../Box";

import DefaultContainer from "./DefaultContainer";

import TextF6 from "../TextF6";
import TextF7 from "../TextF7";
import TextF8 from "../TextF8";

const StyledSubHeading = styled(TextF8)`
  color: #e02c00;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
`;

const StyledHeading = styled(TextF6)`
  color: #e02c00;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const Heading = StyledHeading.withComponent("h2");

const StyledBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  margin-left: -1.5rem;
  margin-right: -1rem;
  @media screen and (min-width: 72em) {
    margin-left: -2.5rem;
    margin-right: -2rem;
  }
`;

const RichText = styled(TextF7)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  line-height: 1.7;
  a {
    color: #9c329c;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default class ImportantBox extends React.Component {
  static defaultProps = {
    heading: "Title",
    description: "description text"
  };

  state = {
    heading: this.props.heading,
    description: this.props.description
  };

  render() {
    return (
      <DefaultContainer>
        <StyledBox
          px={[3, 4]}
          pt={4}
          pb={5}
          mt={5}
          mb={5}
          borderLeft="8px solid #E02C00"
        >
          <StyledSubHeading m={0}>
            <FormattedMessage id="section.block.note" />{" "}
          </StyledSubHeading>
          <Heading m={0}>{this.state.heading}</Heading>
          <RichText
            m={0}
            dangerouslySetInnerHTML={{ __html: this.state.description }}
          />
        </StyledBox>
      </DefaultContainer>
    );
  }
}
