import React from "react";
import * as _ from "lodash";

const tileLength = 10;

const Image = props => {
  const image = props.image;
  const width = image.width * tileLength;
  const height = image.height * tileLength;
  return (
    <svg width={width} height={height}>
      {_.map(image.tiles, (tile, index) => {
        const y = tileLength * Math.trunc(index / image.height);
        const x = tileLength * (index % image.width);
        return (
          <rect
            key={index}
            fill={`rgb(${tile}, ${tile}, ${tile})`}
            width={tileLength}
            height={tileLength}
            x={x}
            y={y}
          />
        );
      })}
    </svg>
  );
};

export default Image;
