import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { hover } from "styled-system";

const stateColor = state => {
  if (state === -1) {
    return "#00CB93";
  } else if (state === 0) {
    return "#E5E5E5";
  } else {
    return "#4840A5";
  }
};

const hoverColor = state => (state === 0 ? "fill: #ccc;" : "");

const hoverEffect = (readonly, state) =>
  readonly
    ? ""
    : `&:hover {
    cursor: pointer;
    ${hoverColor(state)}
  }
  `;

const Rect = styled.rect`
  fill: ${props => stateColor(props.state)}
    ${props => hoverEffect(props.readonly, props.state)};
`;

const TileText = styled.text`
  fill: #fff;
  font-weight: bold;
  font-size: 14px;
  dominant-baseline: central;
  text-anchor: middle;
  cursor: pointer;
`;

const changeState = (props, index, currentState) => {
  if (!props.readonly) {
    props.changeTileState(index, currentState);
  }
};

const tileText = state => (state !== 0 ? state.toString() : "");

const StatefulImage = props => {
  const tileLength = props.tileLength || 30;
  const tileMargin = 3;
  const image = props.image;
  const width = image.width * (tileLength + tileMargin);
  const height = image.height * (tileLength + tileMargin);
  return (
    <svg width={width} height={height}>
      {_.map(image.tiles, (tile, index) => {
        const y = (tileLength + tileMargin) * Math.trunc(index / image.height);
        const x = (tileLength + tileMargin) * (index % image.width);
        return (
          <Rect
            key={index}
            state={tile.state}
            width={tileLength}
            height={tileLength}
            x={x}
            y={y}
            onClick={() => changeState(props, index, tile.state)}
            readonly={props.readonly}
          />
        );
      })}
      {props.readonly
        ? ""
        : _.map(image.tiles, (tile, index) => {
            const y =
              (tileLength + tileMargin) * Math.trunc(index / image.height) +
              tileLength / 2;
            const x =
              (tileLength + tileMargin) * (index % image.width) +
              tileMargin +
              tileLength / 2;
            return (
              <TileText
                key={index}
                x={x}
                y={y}
                onClick={() => changeState(props, index, tile.state)}
              >
                {tileText(tile.state)}
              </TileText>
            );
          })}
    </svg>
  );
};

export default StatefulImage;
