import React from "react";
import Text from "../Text";
import TextF3 from "../TextF3";
import DefaultContainer from "./DefaultContainer";
import styled from "styled-components";

const StyledTextF3 = styled(TextF3)`
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const MarkdownH1 = props => {
  const { ...rest } = props;
  return (
    <DefaultContainer>
      <StyledTextF3 {...rest} />
    </DefaultContainer>
  );
};

export default MarkdownH1;
