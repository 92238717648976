import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import Box from "../Box";
import { FormattedMessage } from "react-intl";

import DefaultContainer from "./DefaultContainer";

import TextF1 from "../TextF1";
import TextF2 from "../TextF2";
import TextF3 from "../TextF3";
import TextF4 from "../TextF4";
import TextF6 from "../TextF6";
import TextF7 from "../TextF7";
import TextF8 from "../TextF8";
//
// const Test = styled(Heading)`
//  font-family: 'tt-norms-pro-bold';
// `

const StyledSubHeading = styled(TextF8)`
  color: #ff7351;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
`;

const SubHeading = StyledSubHeading.withComponent("h3");

const StyledHeading = styled(TextF6)`
  font-family: ${themeGet("fonts.TTNormsProBold")};
  margin-top: 2rem;
  color: #ff7351;
  margin-bottom: 1rem;
`;

const Heading = StyledHeading.withComponent("h2");

const StyledBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  margin-left: -1.5rem;
  margin-right: -1rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
  @media screen and (min-width: 72em) {
    margin-left: -2.5rem;
    margin-right: -2rem;
  }
`;

const RichText = styled(TextF7)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  line-height: 1.7;
  a {
    color: #9c329c;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default class NoteBox extends React.Component {
  static defaultProps = {
    heading: "Title",
    description: "description text"
  };

  state = {
    heading: this.props.heading,
    description: this.props.description
  };

  render() {
    return (
      <DefaultContainer>
        <StyledBox px={[3, 4]} pt={4} pb={5} borderLeft="8px solid #FF7351">
          <StyledSubHeading m={0}>
            <FormattedMessage id="section.block.note" />
          </StyledSubHeading>
          <Heading m={0}>{this.state.heading}</Heading>
          <RichText
            m={0}
            dangerouslySetInnerHTML={{ __html: this.state.description }}
          />
        </StyledBox>
      </DefaultContainer>
    );
  }
}
