import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { injectIntl } from "react-intl";
import RegressionChart from "../../components/Visualizations/RegressionChart";

import Quiz from "../../components/Quiz";

import TextF7 from "../TextF7";

const plotData = [
  { x: 20, y: 80.4 },
  { x: 10, y: 57.1 },
  { x: 13, y: 53.4 }
];

class Exercise17 extends React.Component {
  render() {
    const { quizid } = this.props;

    return (
      <Quiz quizid={quizid}>
        <TextF7>
          <FormattedMessage id="quiz.exercise17.textPart1" />

          <br />
        </TextF7>
        <RegressionChart
          xLabel={this.props.intl.formatMessage({
            id: "quiz.exercise17.xLabel"
          })}
          yLabel={this.props.intl.formatMessage({
            id: "quiz.exercise17.yLabel"
          })}
          data={plotData}
          maxX={22}
          maxY={90}
        />
        <TextF7>
          <FormattedMessage id="quiz.exercise17.textPart2" />
        </TextF7>
        <TextF7>
          <FormattedMessage id="quiz.exercise17.textPart3" />
        </TextF7>
        <TextF7>
          <FormattedMessage id="quiz.exercise17.textPart4" />
        </TextF7>
        <TextF7>
          <FormattedMessage id="quiz.exercise17.textPart5" />
        </TextF7>
      </Quiz>
    );
  }
}

export default injectIntl(Exercise17);
