import React from "react";
import * as d3 from "d3";
import _ from "lodash";
import {
  GraphConstants,
  AxisLine,
  AxisText,
  Curve,
  DataPoint,
  GridLine,
  AxisLabel
} from "./GraphBase";

const width = 600;
const height = 400;
const margin = 50;

const FunctionChart = ({
  minX,
  maxX,
  minY,
  maxY,
  chartFunction,
  xLabel,
  yLabel,
  data,
  yTickValues
}) => {
  const canvasWidth = width - 2 * margin;
  const canvasHeight = height - 2 * margin;

  const graphData = d3
    .range(minX, maxX, 0.05)
    .map(x => ({ x, y: chartFunction(x) }));

  const xScale = d3
    .scaleLinear()
    .domain([minX, maxX])
    .range([0, canvasWidth]);

  const minYValue = _.isNumber(minY) ? minY : _.minBy(graphData, "y").y;
  const maxYValue = _.isNumber(maxY) ? maxY : _.maxBy(graphData, "y").y;
  const yScale = d3
    .scaleLinear()
    .domain([minYValue, maxYValue])
    .range([canvasHeight, 0]);

  const pathData = graphData.map(d => [xScale(d.x), yScale(d.y)]);
  const lineGenerator = d3.line();
  const bottomAxisValues = xScale.ticks(10);
  const leftAxisValues = yTickValues || yScale.ticks(10);

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <g transform={`translate(${margin}, ${height})`}>
        {bottomAxisValues.map((value, index) => (
          <AxisText key={index} x={xScale(value)} y={-30}>
            {value}
          </AxisText>
        ))}
      </g>
      <g transform={`translate(0, ${margin})`}>
        {leftAxisValues.map((value, index) => (
          <AxisText key={index} x={margin - 15} y={yScale(value)}>
            {value}
          </AxisText>
        ))}
      </g>
      <g transform={`translate(${margin}, ${margin})`}>
        {bottomAxisValues.map((value, index) => (
          <GridLine
            key={index}
            x1={xScale(value)}
            x2={xScale(value)}
            y1={0}
            y2={canvasHeight}
          ></GridLine>
        ))}
      </g>
      <g transform={`translate(${margin}, ${margin})`}>
        {leftAxisValues.map((value, index) => (
          <GridLine
            key={index}
            x1={0}
            x2={canvasWidth}
            y1={yScale(value)}
            y2={yScale(value)}
          ></GridLine>
        ))}
      </g>
      <AxisLine
        y1={height - margin}
        y2={height - margin}
        x1={margin}
        x2={width - margin}
      />
      <AxisLine y1={margin} y2={height - margin} x1={margin} x2={margin} />
      <AxisLabel transform={"rotate(-90)"} x={-height / 2} y={15}>
        {yLabel}
      </AxisLabel>
      <AxisLabel x={width / 2} y={height - 10}>
        {xLabel}
      </AxisLabel>
      <g transform={`translate(${margin}, ${margin})`}>
        <Curve d={lineGenerator(pathData)} />
      </g>
      <g transform={`translate(${margin}, ${margin})`}>
        {_.map(data, (d, index) => (
          <DataPoint
            key={index}
            r={GraphConstants.dataPointRadius}
            cx={xScale(d.x)}
            cy={yScale(d.y)}
          />
        ))}
      </g>
    </svg>
  );
};

export default FunctionChart;
