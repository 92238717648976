import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { themeGet } from "styled-system";
import * as _ from "lodash";

import StatefulImage from "../../components/Visualizations/StatefulImage";

import TextF7 from "../TextF7";
import TextF6 from "../TextF6";
import DefaultContainer from "./DefaultContainer";

const width = 5;
const height = 5;

const circle = [
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0
];

const cross = [
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  0,
  0,
  1,
  0,
  1,
  0,
  1,
  0,
  0,
  0,
  1
];

const happyFaces = [
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 1, 1, 1, 1, 1],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 1, 1, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 1, 1, 1, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 1, 0, 0]
];

const sadFaces = [
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1, 0, 0, 0, 1],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 0, 1, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0],
  [0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 1, 0, 1, 0]
];

const calculateDotProduct = (tiles, weights) => {
  const combined = _.zip(tiles, weights);
  const sum = _.reduce(
    combined,
    (sum, pair) => {
      return sum + pair[0] * pair[1];
    },
    0
  );
  return sum;
};

const ClassificationImageContainer = styled.div`
  display: inline-block;
  margin-right: 10px;
  opacity: ${props => (props.disabled ? "0.3" : "1.0")};
`;

const ImageGroup = styled.div`
  margin-bottom: 20px;
`;

const ClassificationImage = ({ states, tileLength = 30, disabled }) => {
  const imageData = {
    width,
    height,
    tiles: _.map(states, state => ({ state }))
  };
  return (
    <ClassificationImageContainer disabled={disabled}>
      <StatefulImage
        readonly={true}
        image={imageData}
        tileLength={tileLength}
      />
    </ClassificationImageContainer>
  );
};

const WeightsImage = ({ weights, updateTileStates }) => {
  const imageData = {
    width,
    height,
    tiles: weights
  };
  return (
    <StatefulImage
      changeTileState={updateTileStates}
      image={imageData}
      tileLength={50}
    />
  );
};

const Exercise23a = props => (
  <DefaultContainer>
    <ImageGroup>
      <ClassificationImage states={_.map(cross, tile => -1 * tile)} />
      <ClassificationImage states={circle} />
    </ImageGroup>
  </DefaultContainer>
);

class Exercise23b extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      weights: _.map(_.range(0, width * height), index => {
        return {
          index,
          state: 0
        };
      })
    };
  }

  render() {
    const weights = this.state.weights;
    const setState = this.setState;
    const updateTileStates = (index, currentState) => {
      const newState = currentState + 1 <= 1 ? currentState + 1 : -1;
      _.find(weights, tile => tile.index === index).state = newState;
      this.setState({ weights });
    };
    const happyFaceCount = _.filter(
      _.map(
        happyFaces,
        tiles => calculateDotProduct(tiles, _.map(weights, "state")) > 0
      )
    ).length;
    const sadFaceCount = _.filter(
      _.map(
        sadFaces,
        tiles => calculateDotProduct(tiles, _.map(weights, "state")) < 0
      )
    ).length;

    return (
      <DefaultContainer>
        <ImageGroup>
          <WeightsImage weights={weights} updateTileStates={updateTileStates} />
        </ImageGroup>
        <TextF6 mt={20} mb={20}>
          {happyFaceCount}/{happyFaces.length}{" "}
          <FormattedMessage id="quiz.exercise23.happyFaces" />
        </TextF6>
        <ImageGroup>
          {_.map(happyFaces, (tiles, index) => (
            <ClassificationImage
              key={index}
              states={tiles}
              tileLength={10}
              disabled={
                calculateDotProduct(tiles, _.map(weights, "state")) <= 0
              }
            />
          ))}
        </ImageGroup>
        <TextF6 mt={20} mb={20}>
          {sadFaceCount}/{happyFaces.length}{" "}
          <FormattedMessage id="quiz.exercise23.sadFaces" />
        </TextF6>
        <ImageGroup>
          {_.map(sadFaces, (tiles, index) => (
            <ClassificationImage
              key={index}
              states={_.map(tiles, tile => -1 * tile)}
              tileLength={10}
              disabled={
                calculateDotProduct(tiles, _.map(weights, "state")) >= 0
              }
            />
          ))}
        </ImageGroup>
      </DefaultContainer>
    );
  }
}

export { Exercise23a, Exercise23b };
