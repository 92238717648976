import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import Box from "../Box";
import DefaultContainer from "./DefaultContainer";

import TextF1 from "../TextF1";
import TextF2 from "../TextF2";
import TextF3 from "../TextF3";
import TextF4 from "../TextF4";
import TextF7 from "../TextF7";

//
// const Test = styled(Heading)`
//  font-family: 'tt-norms-pro-bold';
// `

const StyledSubHeading = styled(TextF3)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  color: white;
`;

const SubHeading = StyledSubHeading.withComponent("h3");

const StyledHeading = styled(TextF3)`
  font-family: ${themeGet("fonts.TTNormsProBold")};
  color: white;
`;

const Heading = StyledHeading.withComponent("h2");

const StyledBox = styled(Box)`
  background: #4844a3;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
`;

export default class Counter extends React.Component {
  static defaultProps = {
    heading: "Title",
    description: "description text"
  };

  state = {
    heading: this.props.heading,
    description: this.props.description
  };

  render() {
    return (
      <DefaultContainer>
        <StyledBox p={4} mt={5} mb={6}>
          <SubHeading m={0}>{this.state.heading}</SubHeading>
          <TextF7 color="white" m={0}>
            {this.state.description}
          </TextF7>
        </StyledBox>
      </DefaultContainer>
    );
  }
}
