import React from "react";

import styled from "styled-components";
import { themeGet } from "styled-system";
import Box from "../Box";
import DefaultContainer from "./DefaultContainer";
import { FormattedMessage } from "react-intl";

import TextF1 from "../TextF1";
import TextF2 from "../TextF2";
import TextF3 from "../TextF3";
import TextF4 from "../TextF4";
import TextF6 from "../TextF6";
import TextF7 from "../TextF7";
import TextF8 from "../TextF8";

const terminologies = [
  { id: 1, title: "Slide 1 Title", footnote: "Slide 1 Text" },
  { id: 2, title: "Slide 2 Title", footnote: "Slide 2 Text" },
];

const StyledSubHeading = styled(TextF8)`
  color: #e15fbd;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
  margin-bottom: 2rem;
`;

const RichText = styled(TextF7)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  line-height: 1.7;
  margin-top: 2rem;
  a {
    color: #9c329c;
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledBox = styled(Box)`
  background: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  margin-left: -1.5rem;
  margin-right: -1rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
  @media screen and (min-width: 72em) {
    margin-left: -2.5rem;
    margin-right: -2rem;
  }
`;

const Footnote = styled.h2`
  color: #9c329c;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  margin-bottom: -2.2rem;
  margin-top: 1rem;
  float: right;
  font-size: 0.9rem;
  font-weight: 400;
`;

const ChildrenWrapper = styled.div`
  margin-top: 2rem;
  margin-left: -2rem;

  width: 400px;
`;

export default class AdditionalResources extends React.Component {
  static defaultProps = {
    terminologies: terminologies,
  };

  state = {
    terminologies: JSON.parse(this.props.terminologies),
  };

  render() {
    const content = this.state.terminologies.map((terminologies, index) => (
      <div key={index}>
        <RichText
          m={0}
          dangerouslySetInnerHTML={{ __html: terminologies.content }}
        />
      </div>
    ));

    return (
      <DefaultContainer>
        <StyledBox px={[3, 4]} pt={4} pb={5} borderLeft="8px solid #E15FBD">
          <StyledSubHeading m={0}>
            <FormattedMessage id="section.block.additionalResources" />
          </StyledSubHeading>
          {content}
          {this.props.children && (
            <ChildrenWrapper>{this.props.children}</ChildrenWrapper>
            )}
          {this.props.footnote && (
            <Footnote
              dangerouslySetInnerHTML={{ __html: this.props.footnote }}
              m={0}
            />
          )}
        </StyledBox>
      </DefaultContainer>
    );
  }
}
