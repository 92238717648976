import React from "react";
import Text from "../Text";
import TextF5 from "../TextF5";
import DefaultContainer from "./DefaultContainer";
import styled from "styled-components";
import Box from "../Box";

const StyledBox = styled(Box)`
  padding: 2rem;
  background: #d6f0f9;
  margin-bottom: 4rem;
  margin-top: 4rem;
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.5;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
`;
export default class CodeSnippet extends React.Component {
  static defaultProps = {
    text: "Missing text attribute"
  };

  state = {
    text: this.props.text
  };
  render() {
    return (
      <DefaultContainer>
        <StyledBox>{this.props.text}</StyledBox>
      </DefaultContainer>
    );
  }
}
