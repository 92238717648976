import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { injectIntl } from "react-intl";
import RegressionChart from "../../components/Visualizations/RegressionChart";

import Quiz from "../../components/Quiz";

import TextF7 from "../TextF7";

const plotData = [
  { x: 20, y: 80.4 },
  { x: 14, y: 63.9 },
  { x: 12, y: 46.6 },
  { x: 11, y: 72 },
  { x: 6, y: 50.7 },
  { x: 10, y: 57.1 },
  { x: 13, y: 74.9 },
  { x: 10, y: 62.9 },
  { x: 14, y: 71.3 },
  { x: 9, y: 43.4 },
  { x: 13, y: 53.4 },
  { x: 16, y: 80 },
  { x: 16, y: 80.1 },
  { x: 16, y: 77.4 }
];

class LinearRegressionExercise4 extends React.Component {
  render() {
    const { quizid } = this.props;
    return (
      <Quiz quizid={quizid}>
        <TextF7>
          <FormattedMessage id="quiz.exercise18.textPart1" />
        </TextF7>
        <RegressionChart
          xLabel={this.props.intl.formatMessage({
            id: "quiz.exercise18.xLabel"
          })}
          yLabel={this.props.intl.formatMessage({
            id: "quiz.exercise18.yLabel"
          })}
          data={plotData}
          maxX={22}
          maxY={90}
        />
        <TextF7>
          <FormattedMessage id="quiz.exercise18.textPart2" />
        </TextF7>
        <TextF7>
          <FormattedMessage id="quiz.exercise18.textPart3" />
        </TextF7>
      </Quiz>
    );
  }
}

export default injectIntl(LinearRegressionExercise4);
