import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { injectIntl } from "react-intl";
import LogisticRegressionChart from "../../components/Visualizations/LogisticRegressionChart";

import Quiz from "../../components/Quiz";

import TextF7 from "../TextF7";

class Exercise19 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        { x: 14.62562917, y: 1.0 },
        { x: 11.83430826, y: 1.0 },
        { x: 15.16224832, y: 1.0 },
        { x: 12.58546897, y: 1.0 },
        { x: 13.11403893, y: 1.0 },
        { x: 14.2706756, y: 1.0 },
        { x: 12.87650042, y: 1.0 },
        { x: 10.32092847, y: 1.0 },
        { x: 13.96015141, y: 1.0 },
        { x: 9.69321136, y: 1.0 },
        { x: 12.83833043, y: 1.0 },
        { x: 13.35893525, y: 1.0 },
        { x: 10.22716705, y: 1.0 },
        { x: 9.66679189, y: 1.0 },
        { x: 12.7635011, y: 1.0 },
        { x: 9.94524671, y: 1.0 },
        { x: 9.98108602, y: 1.0 },
        { x: 12.18886972, y: 1.0 },
        { x: 10.56556885, y: 1.0 },
        { x: 14.22196286, y: 1.0 },
        { x: 12.47837464, y: 1.0 },
        { x: 15.744123, y: 1.0 },
        { x: 14.57571601, y: 1.0 },
        { x: 15.5169715, y: 1.0 },
        { x: 10.5036877, y: 1.0 },
        { x: 10.08182354, y: 1.0 },
        { x: 8.4840712, y: 1.0 },
        { x: 9.20631184, y: 1.0 },
        { x: 12.33525853, y: 1.0 },
        { x: 12.25625575, y: 1.0 },
        { x: 13.38594038, y: 1.0 },
        { x: 13.20890212, y: 1.0 },
        { x: 14.67290973, y: 1.0 },
        { x: 12.07724714, y: 1.0 },
        { x: 11.51262202, y: 1.0 },
        { x: 13.83044464, y: 1.0 },
        { x: 11.12445578, y: 1.0 },
        { x: 15.00004097, y: 1.0 },
        { x: 17.4453125, y: 1.0 },
        { x: 15.80193537, y: 1.0 },
        { x: 2.9269639, y: 0.0 },
        { x: 6.05441506, y: 0.0 },
        { x: 3.52601205, y: 0.0 },
        { x: 7.83319354, y: 0.0 },
        { x: 4.95959781, y: 0.0 },
        { x: 5.26498649, y: 0.0 },
        { x: 9.19267124, y: 0.0 },
        { x: 5.10032875, y: 0.0 },
        { x: 4.4596092, y: 0.0 },
        { x: 3.3636181, y: 0.0 },
        { x: 3.58350945, y: 0.0 },
        { x: 4.21980438, y: 0.0 },
        { x: 7.00634043, y: 0.0 },
        { x: 1.78056895, y: 0.0 },
        { x: 4.12428747, y: 0.0 },
        { x: 5.01040347, y: 0.0 },
        { x: 5.44887967, y: 0.0 },
        { x: 2.48619262, y: 0.0 },
        { x: 1.42746508, y: 0.0 },
        { x: 1.85395033, y: 0.0 },
        { x: 4.72708876, y: 0.0 },
        { x: 5.30355204, y: 0.0 },
        { x: 7.55910837, y: 0.0 },
        { x: 4.0093867, y: 0.0 },
        { x: 4.24379712, y: 0.0 },
        { x: 0.08308462, y: 0.0 },
        { x: 5.50348607, y: 0.0 },
        { x: 5.71319369, y: 0.0 },
        { x: 1.24762303, y: 0.0 },
        { x: 7.27987562, y: 0.0 },
        { x: 4.36898554, y: 0.0 },
        { x: 4.26293991, y: 0.0 },
        { x: 3.99160625, y: 0.0 },
        { x: 2.74201934, y: 0.0 },
        { x: 6.4868309, y: 0.0 },
        { x: 5.18134547, y: 0.0 },
        { x: 1.35958393, y: 0.0 },
        { x: 2.11425955, y: 0.0 },
        { x: 9.0892649, y: 0.0 },
        { x: 4.36015015, y: 0.0 }
      ],
      minX: -5,
      maxX: 20,
      beta: 0.49570342,
      alpha: 3.73213574 / 0.49570342,
      xLabel: "Hours studied",
      yLabel: "Probability of passing",
      xLabel: this.props.intl.formatMessage({ id: "quiz.exercise19.xLabel" }),
      yLabel: this.props.intl.formatMessage({ id: "quiz.exercise19.yLabel" })
    };
  }

  render() {
    const { data, minX, maxX, alpha, beta, xLabel, yLabel } = this.state;
    const { quizid } = this.props;

    return (
      <Quiz quizid={quizid}>
        <LogisticRegressionChart
          data={data}
          maxX={maxX}
          minX={minX}
          alpha={alpha}
          beta={beta}
          xLabel={xLabel}
          yLabel={yLabel}
        />
        <TextF7>
          <FormattedMessage id="quiz.exercise19.textPart1" />
        </TextF7>
        <TextF7>
          <FormattedMessage id="quiz.exercise19.textPart2" />
        </TextF7>
        <TextF7>
          <FormattedMessage id="quiz.exercise19.textPart3" />
        </TextF7>
        <TextF7>
          <FormattedMessage id="quiz.exercise19.textPart4" />
        </TextF7>
      </Quiz>
    );
  }
}

export default injectIntl(Exercise19);
