import React from "react";
import Text from "../Text";
import TextF4 from "../TextF4";
import DefaultContainer from "./DefaultContainer";
import styled from "styled-components";

const StyledTextF4 = styled(TextF4)`
  margin-top: 4rem;
  margin-bottom: 1rem;
`;
const MarkdownH2 = props => {
  const { ...rest } = props;
  return (
    <DefaultContainer>
      <StyledTextF4 {...rest} />
    </DefaultContainer>
  );
};

export default MarkdownH2;
