import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import Text from "../Text";
import TextF4 from "../TextF4";
import { SmallContainer } from "../Grid";

//Certain greek letters are showing outside of the unicode-range when strong
//Therefore, the wrong font is loading
//No great way to pass the language key into this component (?)
//Short-term solution, get lang based on URL for Greek.

const StyledLead = styled(TextF4)`
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  line-height: 1.35;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 2rem;
  letter-spacing: -1px;
`;

const MarkdownLead = props => {
  const { ...rest } = props;
  return (
    <SmallContainer mb={[5]} mt={[3]}>
      <StyledLead {...rest} />
    </SmallContainer>
  );
};

export default MarkdownLead;
