import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import FunctionChart from "../../components/Visualizations/FunctionChart";
import { GraphFunctions } from "../../components/Visualizations/GraphBase";

import Quiz from "../../components/Quiz";

import TextF7 from "../TextF7";

export default class Exercise22b extends React.Component {
  render() {
    const minX = -10;
    const maxX = 10;
    return (
      <Quiz quizid="5aec5dd906ee0000047c5992">
        <FunctionChart
          xLabel="Input"
          yLabel="Sigmoid output"
          maxX={maxX}
          minX={minX}
          chartFunction={x => GraphFunctions.sigmoid(x, 0, 1)}
        />
        <FunctionChart
          xLabel="Input"
          yLabel="Linear output"
          maxX={maxX}
          minX={minX}
          chartFunction={GraphFunctions.linear}
        />
      </Quiz>
    );
  }
}
