import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { userDetails } from "../../services/moocfi";
import DefaultContainer from "./DefaultContainer";
import { submitFeedback } from "../../services/feedback";
import { themeGet } from "styled-system";
import _ from "lodash";

import Box from "../Box";

import TextF4 from "../TextF4";

import TextF6 from "../TextF6";

const StyledBox = styled(Box)`
  margin: 4rem 0 8rem;
  ul {
    margin-top: 2rem;
    padding: 0;
    font-size: 1.125rem;
    color: #29264c;
    margin-bottom: 1rem;
    list-style: none;
    border-top: 1px solid #d8d8d8;
    li {
      font-family: ${themeGet("fonts.alkesRegular")};
      padding: 1.75rem 0 1.75rem 2.25rem;
      line-height: 1.8;
      position: relative;
      border-bottom: 1px solid #d8d8d8;
      :before {
        content: "";
        width: 12px;
        height: 12px;
        background: #19ca94;
        border-radius: 50%;
        position: absolute;
        top: 2.4rem;
        left: 0;
      }
    }
  }
`;

class PartSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      heading: this.props.heading,
      listItems: JSON.parse(this.props.listitems),
      message: "",
      email: "",
      chapter: this.props.chapter,
      messageSent: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  componentDidMount() {
    userDetails()
      .then(user => {
        const userEmail = _.get(user, "email");
        this.setState({ email: userEmail });
      })
      .catch(error => {
        console.log("Failed to set email", error);
      });
  }

  handleSubmit(event) {
    submitFeedback(this.state.message, this.state.chapter, this.state.email);
    this.setState({ messageSent: true });
    event.preventDefault();
  }

  render() {
    const content = this.state.listItems.map((listItem, index) => (
      <li key={index}>{listItem.content}</li>
    ));

    return (
      <DefaultContainer px={[4]}>
        <StyledBox>
          <TextF4 m={0}>{this.state.heading}</TextF4>
          <ul>{content}</ul>
          <TextF6 mt={5} mb={0}>
            <FormattedMessage id="section.partSummary.spectrumTextPart1" />{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.intl.formatMessage({ id: "routes.SPECTRUM" })}
            >
              <FormattedMessage id="section.partSummary.spectrum" />
            </a>
            <FormattedMessage id="section.partSummary.spectrumTextPart2" />
          </TextF6>
        </StyledBox>
      </DefaultContainer>
    );
  }
}

export default injectIntl(PartSummary);
