import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { themeGet } from "styled-system";
import { injectIntl } from "react-intl";
import FunctionChart from "../../components/Visualizations/FunctionChart";
import { GraphFunctions } from "../../components/Visualizations/GraphBase";

import Quiz from "../../components/Quiz";

import TextF7 from "../TextF7";

class Exercise22 extends React.Component {
  render() {
    const { quizid } = this.props;
    const minX = -10;
    const maxX = 10;
    return (
      <Quiz quizid={quizid}>
        <TextF7>
          <FormattedMessage id="quiz.exercise22.textPart1" />
        </TextF7>
        <FunctionChart
          xLabel={this.props.intl.formatMessage({
            id: "quiz.exercise22.sigmoidXLabel"
          })}
          yLabel={this.props.intl.formatMessage({
            id: "quiz.exercise22.sigmoidYLabel"
          })}
          maxX={maxX}
          minX={minX}
          chartFunction={x => GraphFunctions.sigmoid(x, 0, 1)}
          yTickValues={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
        />
        <FunctionChart
          xLabel={this.props.intl.formatMessage({
            id: "quiz.exercise22.stepXLabel"
          })}
          yLabel={this.props.intl.formatMessage({
            id: "quiz.exercise22.stepYLabel"
          })}
          maxX={maxX}
          minX={minX}
          chartFunction={GraphFunctions.step}
          yTickValues={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
        />
        <FunctionChart
          xLabel={this.props.intl.formatMessage({
            id: "quiz.exercise22.identityXLabel"
          })}
          yLabel={this.props.intl.formatMessage({
            id: "quiz.exercise22.identityYLabel"
          })}
          maxX={maxX}
          minX={minX}
          chartFunction={GraphFunctions.linear}
          yTickValues={[-10, -8, -6, -4, -2, 0, 2, 4, 6, 8, 10]}
        />
      </Quiz>
    );
  }
}

export default injectIntl(Exercise22);
