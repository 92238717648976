import React from "react";
import styled from "styled-components";
import { SmallContainer } from "../Grid";
import Box from "../Box";
import { injectIntl } from "react-intl";

import definingAiUrl from "../../images/defining-ai.svg";
import turingTestUrl from "../../images/1_3_turing-test.svg";
import chineseRoomUrl from "../../images/1_3_chinese-room.svg";
import chickenCrossingUrl from "../../images/2_1_chicken-crossing.svg";
import ticTacTowUrl from "../../images/2_3-tic-tac-toe.svg";
import selfDrivingCarUrl from "../../images/3_1-self-driving-car.svg";
import spamOrHamUrl from "../../images/3_3_spam-or-ham.svg";
import priceRealEstateUrl from "../../images/4_1_price-real-estate.svg";
import nearestNeighborUrl from "../../images/4_2_nearest-neighbor.svg";
import nearestNeighborGraphUrl from "../../images/4_2_nearest-neighbor-graph.svg";
import recommendationUrl from "../../images/4_2_recommendation.svg";
import supervisedLearningUrl from "../../images/4_1_supervised-learning.svg";
import search1Url from "../../images/2_1_search-1.svg";
import search2Url from "../../images/2_1_search-2.svg";
import chickenCrossing1Url from "../../images/2_1_chicken-crossing-1.svg";
import chickenCrossing2Url from "../../images/2_1_chicken-crossing-2.svg";
import chickenCrossing3Url from "../../images/2_1_chicken-crossing-3.svg";
import chickenCrossing4Url from "../../images/2_1_chicken-crossing-4.svg";
import chickenCrossing5Url from "../../images/2_1_chicken-crossing-5.svg";
import pokerUrl from "../../images/3_1_poker.svg";
import oddchangeUrl from "../../images/3_2-oddchange.svg";
import hammockUrl from "../../images/6_1-hammock.svg";
import winterUrl from "../../images/6_1_winter.svg";
import terminatorUrl from "../../images/6_1_terminator.svg";
import carstopUrl from "../../images/5_2-carstop.svg";
import ganUrl from "../../images/5_3-gan.svg";
import brainUrl from "../../images/5_1-brain.svg";
import eyesUrl from "../../images/3_1_eyes.svg";
import diceUrl from "../../images/2_3-dice.svg";
import chessUrl from "../../images/2_3-chess.svg";
import mnistUrl from "../../images/4_1-mnist.svg";
import aToBUrl from "../../images/2_1_a-to-b.svg";
import implication1Url from "../../images/6_2_implication-1.svg";
import implication2Url from "../../images/6_2_implication-2.svg";
import implication3Url from "../../images/6_2_implication-3.svg";
import implication4Url from "../../images/6_2_implication-4.svg";

import gameTree1Url from "../../images/2_3_game-tree-1.svg";
import gameTree2Url from "../../images/2_3_game-tree-2.svg";
import gameTree3Url from "../../images/2_3_game-tree-3.svg";
import gameTree4Url from "../../images/2_3_game-tree-4.svg";

import bayesRule1Url from "../../images/3_2_bayes-rule-1.svg";
import bayesRule2Url from "../../images/3_2_bayes-rule-2.svg";

import example1Url from "../../images/1_1_example-1.svg";
import example2Url from "../../images/1_1_example-2.svg";
import example3Url from "../../images/1_1_example-3.svg";
import definedaiUrl from "../../images/1_1_defineai.svg";

// fi specific
import definingAiFI from "../../images/1_1_defineai_FI.svg";
import chickenCrossing2UrlFI from "../../images/2_1_chicken-crossing-2_FI.svg";
import chickenCrossing3UrlFI from "../../images/2_1_chicken-crossing-3_FI.svg";
import chickenCrossing4UrlFI from "../../images/2_1_chicken-crossing-4_FI.svg";
import chickenCrossing5UrlFI from "../../images/2_1_chicken-crossing-5_FI.svg";
import bayesRule1UrlFI from "../../images/3_2_Bayes-Rule_1_FI.svg";
import bayesRule2UrlFI from "../../images/3_2_Bayes-Rule_2_FI.svg";
import nearestNeighborUrlFI from "../../images/4_2_Nearest-neighbor-1_FI.svg";
import gameTree4FI from "../../images/2_3_game-tree-4_FI.svg";

// se specific
import example2SE from "../../images/1_1_example-2_SE.svg";
import search1SE from "../../images/2_1_search-1_SE.svg";
import search2SE from "../../images/2_1_search-2_SE.svg";
import spamOrHamSE from "../../images/3_3_spam-or-ham_SE.svg";

import definingAiSE from "../../images/1_1_defineai_SE.svg";
import chickenCrossing2UrlSE from "../../images/2_1_chicken-crossing-2_SE.svg";
import chickenCrossing3UrlSE from "../../images/2_1_chicken-crossing-3_SE.svg";
import chickenCrossing4UrlSE from "../../images/2_1_chicken-crossing-4_SE.svg";
import chickenCrossing5UrlSE from "../../images/2_1_chicken-crossing-5_SE.svg";
import bayesRule1UrlSE from "../../images/3_2_Bayes-Rule_1_SE.svg";
import bayesRule2UrlSE from "../../images/3_2_Bayes-Rule_2_SE.svg";
import nearestNeighborUrlSE from "../../images/4_2_nearest-neighbor_SE.svg";

// de specific
import bayesRule1UrlDE from "../../images/3_2_Bayes-Rule_1_DE.svg";
import bayesRule2UrlDE from "../../images/3_2_Bayes-Rule_2_DE.svg";
import nearestNeighborUrlDE from "../../images/4_2_nearest-neighbor_DE.svg";
import recommendationUrlDE from "../../images/4_2_recommendation_DE.svg";
import definingAiDE from "../../images/1_1_defineai_DE.svg";
import search1DE from "../../images/2_1_search-1_DE.svg";
import search2DE from "../../images/2_1_search-2_DE.svg";
import gameTree4UrlDe from "../../images/2_3_game-tree-4_DE.svg";
import spamOrHamDE from "../../images/3_3_spam-or-ham_DE.svg";
import chickenCrossing1UrlDE from "../../images/2_1_chicken-crossing-1_DE.svg";
import chickenCrossing2UrlDE from "../../images/2_1_chicken-crossing-2_DE.svg";
import chickenCrossing3UrlDE from "../../images/2_1_chicken-crossing-3_DE.svg";
import chickenCrossing4UrlDE from "../../images/2_1_chicken-crossing-4_DE.svg";

// ee specific
import bayesRule1UrlEE from "../../images/3_2_Bayes-Rule_1_EE.svg";
import bayesRule2UrlEE from "../../images/3_2_Bayes-Rule_2_EE.svg";
import nearestNeighborUrlEE from "../../images/4_2_nearest-neighbor_EE.svg";
import recommendationUrlEE from "../../images/4_2_recommendation_EE.svg";
import definingAiEE from "../../images/1_1_defineai_EE.svg";
import search1EE from "../../images/2_1_search-1_EE.svg";
import search2EE from "../../images/2_1_search-2_EE.svg";
import gameTree4UrlEE from "../../images/2_3_game-tree-4_EE.svg";
import spamOrHamEE from "../../images/3_3_spam-or-ham_EE.svg";

import chickenCrossing1UrlEE from "../../images/2_1_chicken-crossing-1_EE.svg";
import chickenCrossing2UrlEE from "../../images/2_1_chicken-crossing-2_EE.svg";
import chickenCrossing3UrlEE from "../../images/2_1_chicken-crossing-3_EE.svg";
import chickenCrossing4UrlEE from "../../images/2_1_chicken-crossing-4_EE.svg";

import towersOfHanoiEE from "../../images/Exercise-6_1_EE.svg";

//LT specific
import bayesRule1LT from "../../images/3_2_Bayes-Rule_1_LT.svg";
import bayesRule2LT from "../../images/3_2_Bayes-Rule_2_LT.svg";
import nearestNeighborGraphLT from "../../images/4_2_nearest-neighbor_LT.svg";
import recommendationLT from "../../images/4_2_recommendation_LT.svg";
import definingAiLT from "../../images/1_1_defineai_LT.svg";
import search1LT from "../../images/2_1_search-1_LT.svg";
import search2LT from "../../images/2_1_search-2_LT.svg";
import gameTree4LT from "../../images/2_3_game-tree-4_LT.svg";
import spamOrHamLT from "../../images/3_3_spam-or-ham_LT.svg";
import chickenCrossing1LT from "../../images/2_1_chicken-crossing-1_LT.svg";
import chickenCrossing2LT from "../../images/2_1_chicken-crossing-2_LT.svg";
import chickenCrossing3LT from "../../images/2_1_chicken-crossing-3_LT.svg";
import chickenCrossing4LT from "../../images/2_1_chicken-crossing-4_LT.svg";

//NO specific
import definingAiNB from "../../images/1_1_defineai_NB.svg";
import searchOneNB from "../../images/2_1_search-1_NB.svg";
import searchTwoNB from "../../images/2_1_search-2_NB.svg";
import gameTree4 from "../../images/2.3_Gametree_4_NO.svg";
import chickenCrossing1NB from "../../images/2_1_chicken-crossing-1_NB.svg";
import chickenCrossing2NB from "../../images/2_1_chicken-crossing-2_NB.svg";
import chickenCrossing3NB from "../../images/2_1_chicken-crossing-3_NB.svg";
import chickenCrossing4NB from "../../images/2_1_chicken-crossing-4_NB.svg";
import spamOrHamNB from "../../images/3_3_spam-or-ham_NB.svg";
import nearestNeighborNB from "../../images/4.2_Trainingset_NO.svg";
import recommendationNB from "../../images/4.2_Musicrecommendations_NO.svg";
import bayesRule1 from "../../images/3_2_BayesRule_1_NO.svg";
import bayesRule2 from "../../images/3_2_BayesRule_2_NO.svg";

// FR specific

import bayesRule1UrlFR from "../../images/3_2_Bayes-Rule_1_FR.svg";
import bayesRule2UrlFR from "../../images/3_2_Bayes-Rule_2_FR.svg";
import nearestNeighborUrlFR from "../../images/4_2_nearest-neighbor_FR.svg";
import recommendationUrlFR from "../../images/4_2_recommendation_FR.svg";
import definingAiFR from "../../images/1_1_defineai_FR.svg";
import search1FR from "../../images/2_1_Search-1_FR.svg";
import search2FR from "../../images/2_1_search-2_FR.svg";
import gameTree4UrlFR from "../../images/2_3_game-tree-4_FR.svg";
import spamOrHamFR from "../../images/3_3_spam-or-ham_FR.svg";
import chickenCrossing1UrlFR from "../../images/2_1_chicken-crossing-1_FR.svg";
import chickenCrossing2UrlFR from "../../images/2_1_chicken-crossing-2_FR.svg";
import chickenCrossing3UrlFR from "../../images/2_1_chicken-crossing-3_FR.svg";
import chickenCrossing4UrlFR from "../../images/2_1_chicken-crossing-4_FR.svg";
import towersOfHanoiFR from "../../images/2.1_Exercise_6.1_FR.svg";

//lv specific
import bayesRule1LV from "../../images/3_2_Bayes-Rule_1_LV.svg";
import bayesRule2LV from "../../images/3_2_Bayes-Rule_2_LV.svg";
import nearestNeighborGraphLV from "../../images/4_2_nearest-neighbor-graph_LV.svg";
import recommendationLV from "../../images/4_2_recommendation_LV.svg";
import definingAiLV from "../../images/1_1_defineai_LV.svg";
import search1LV from "../../images/2_1_search-1_LV.svg";
import search2LV from "../../images/2_1_search-2_LV.svg";
import gameTree4LV from "../../images/2_3_game-tree-4_LV.svg";
import spamOrHamLV from "../../images/3_3_spam-or-ham_LV.svg";
import chickenCrossing1LV from "../../images/2_1_chicken-crossing_1_LV.svg";
import chickenCrossing2LV from "../../images/2_1_chicken-crossing_2_LV.svg";
import chickenCrossing3LV from "../../images/2_1_chicken-crossing_3_LV.svg";
import chickenCrossing4LV from "../../images/2_1_chicken-crossing_4_LV.svg";

//nl specific
import bayesRule1NL from "../../images/3_2_Bayes-Rule_1_NL.svg";
import bayesRule2NL from "../../images/3_2_Bayes-Rule_2_NL.svg";
import nearestNeighborGraphNL from "../../images/4_2_nearest-neighbor_NL.svg";
import recommendationNL from "../../images/4_2_recommendation_NL.svg";
import definingAiNL from "../../images/1_1_defineai_NL.svg";
import search1NL from "../../images/2_1_search-1_NL.svg";
import search2NL from "../../images/2_1_search-2_NL.svg";
import gameTree4NL from "../../images/2_3_game-tree-4_NL.svg";
import spamOrHamNL from "../../images/3_3_spam-or-ham_NL.svg";
import chickenCrossing1NL from "../../images/2_1_chicken-crossing-1_NL.svg";
import chickenCrossing2NL from "../../images/2_1_chicken-crossing-2_NL.svg";
import chickenCrossing3NL from "../../images/2_1_chicken-crossing-3_NL.svg";
import chickenCrossing4NL from "../../images/2_1_chicken-crossing-4_NL.svg";

//it specific
import bayesRule1UrlIT from "../../images/3_2_Bayes-Rule_1_IT.svg";
import bayesRule2UrlIT from "../../images/3_2_Bayes-Rule_2_IT.svg";
import nearestNeighborUrlIT from "../../images/4_2_nearest-neighbor_IT.svg";
import recommendationUrlIT from "../../images/4_2_recommendation_IT.svg";
import definingAiIT from "../../images/1_1_defineai_IT.svg";
import search1IT from "../../images/2_1_search-1_IT.svg";
import search2IT from "../../images/2_1_search-2_IT.svg";
import gameTree4UrlIT from "../../images/2_3_game-tree-4_IT.svg";
import spamOrHamIT from "../../images/3_3_spam-or-ham_IT.svg";
import chickenCrossing1UrlIT from "../../images/2_1_chicken-crossing-1_IT.svg";
import chickenCrossing2UrlIT from "../../images/2_1_chicken-crossing-2_IT.svg";
import chickenCrossing3UrlIT from "../../images/2_1_chicken-crossing-3_IT.svg";
import chickenCrossing4UrlIT from "../../images/2_1_chicken-crossing-4_IT.svg";
import towersOfHanoiIT from "../../images/2.1_Exercise_6.1_IT.svg";

//mt specific
import bayesRule1MT from "../../images/3_2_Bayes-Rule_1_MT.svg";
import bayesRule2MT from "../../images/3_2_Bayes-Rule_2_MT.svg";
import nearestNeighborGraphMT from "../../images/4_2_nearest-neighbor_MT.svg";
import recommendationMT from "../../images/4_2_recommendation_MT.svg";
import definingAiMT from "../../images/1_1_defineai_MT.svg";
import search1MT from "../../images/2_1_search-1_MT.svg";
import search2MT from "../../images/2_1_search-2_MT.svg";
import gameTree4MT from "../../images/2_3_game-tree-4_MT.svg";
import spamOrHamMT from "../../images/3_3_spam-or-ham_MT.svg";
import chickenCrossing1MT from "../../images/2_1_chicken-crossing-1_MT.svg";
import chickenCrossing2MT from "../../images/2_1_chicken-crossing-2_MT.svg";
import chickenCrossing3MT from "../../images/2_1_chicken-crossing-3_MT.svg";
import chickenCrossing4MT from "../../images/2_1_chicken-crossing-4_MT.svg";

// hr specific
import bayesRule1HR from "../../images/3_2_Bayes-Rule_1_HR.svg";
import bayesRule2HR from "../../images/3_2_Bayes-Rule_2_HR.svg";
import nearestNeighborGraphHR from "../../images/4_2_nearest-neighbor_HR.svg";
import recommendationHR from "../../images/4_2_recommendation_HR.svg";
import definingAiHR from "../../images/1_1_defineai_HR.svg";
import search1HR from "../../images/2_1_search-1_HR.svg";
import search2HR from "../../images/2_1_search-2_HR.svg";
import gameTree4HR from "../../images/2_3_game-tree-4_HR.svg";
import spamOrHamHR from "../../images/3_3_spam-or-ham_HR.svg";
import chickenCrossing1HR from "../../images/2_1_chicken-crossing-1_HR.svg";
import chickenCrossing2HR from "../../images/2_1_chicken-crossing-2_HR.svg";
import chickenCrossing3HR from "../../images/2_1_chicken-crossing-3_HR.svg";
import chickenCrossing4HR from "../../images/2_1_chicken-crossing-4_HR.svg";
//pl specific
import bayesRule1PL from "../../images/3_2_Bayes-Rule_1_PL.svg";
import bayesRule2PL from "../../images/3_2_Bayes-Rule_2_PL.svg";
import nearestNeighborGraphPL from "../../images/4_2_nearest-neighbor_PL.svg";
import recommendationPL from "../../images/4_2_recommendation_PL.svg";
import definingAiPL from "../../images/1_1_defineai_PL.svg";
import search1PL from "../../images/2_1_search-1_PL.svg";
import search2PL from "../../images/2_1_search-2_PL.svg";
import gameTree4PL from "../../images/2_3_game-tree-4_PL.svg";
import spamOrHamPL from "../../images/3_3_spam-or-ham_PL.svg";
import chickenCrossing1PL from "../../images/2_1_chicken-crossing-1_PL.svg";
import chickenCrossing2PL from "../../images/2_1_chicken-crossing-2_PL.svg";
import chickenCrossing3PL from "../../images/2_1_chicken-crossing-3_PL.svg";
import chickenCrossing4PL from "../../images/2_1_chicken-crossing-4_PL.svg";

//ga specific
import bayesRule1GA from '../../images/3_2_Bayes-Rule_1_GA.svg'
import bayesRule2GA from '../../images/3_2_Bayes-Rule_2_GA.svg'
import nearestNeighborGraphGA from '../../images/4_2_nearest-neighbor_GA.svg'
import recommendationGA from '../../images/4_2_recommendation_GA.svg'
import definingAiGA from '../../images/1_1_defineai_GA.svg'
import search1GA from '../../images/2_1_search-1_GA.svg'
import search2GA from '../../images/2_1_search-2_GA.svg'
import gameTree4GA from '../../images/2_3_game-tree-4_GA.svg'
import spamOrHamGA from '../../images/3_3_spam-or-ham_GA.svg'
import chickenCrossing1GA from '../../images/2_1_chicken-crossing-1_GA.svg'
import chickenCrossing2GA from '../../images/2_1_chicken-crossing-2_GA.svg'
import chickenCrossing3GA from '../../images/2_1_chicken-crossing-3_GA.svg'
import chickenCrossing4GA from '../../images/2_1_chicken-crossing-4_GA.svg'
//SK specific
import bayesRule1SK from '../../images/3_2_Bayes-Rule_1_SK.svg'
import bayesRule2SK from '../../images/3_2_Bayes-Rule_2_SK.svg'
import nearestNeighborGraphSK from '../../images/4_2_nearest-neighbor_SK.svg'
import recommendationSK from '../../images/4_2_recommendation_SK.svg'
import definingAiSK from '../../images/1_1_defineai_SK.svg'
import search1SK from '../../images/2_1_search-1_SK.svg'
import search2SK from '../../images/2_1_search-2_SK.svg'
import gameTree4SK from '../../images/2_3_game-tree-4_SK.svg'
import spamOrHamSK from '../../images/3_3_spam-or-ham_SK.svg'
import chickenCrossing1SK from '../../images/2_1_chicken-crossing-1_SK.svg'
import chickenCrossing2SK from '../../images/2_1_chicken-crossing-2_SK.svg'
import chickenCrossing3SK from '../../images/2_1_chicken-crossing-3_SK.svg'
import chickenCrossing4SK from '../../images/2_1_chicken-crossing-4_SK.svg'
//da specific
import bayesRule1DA from "../../images/3_2_Bayes-Rule_1_DA.svg";
import bayesRule2DA from "../../images/3_2_Bayes-Rule_2_DA.svg";
import nearestNeighborGraphDA from "../../images/4_2_nearest-neighbor_DA.svg";
import recommendationDA from "../../images/4_2_recommendation_DA.svg";
import definingAiDA from "../../images/1_1_defineai_DA.svg";
import search1DA from "../../images/2_1_search-1_DA.svg";
import search2DA from "../../images/2_1_search-2_DA.svg";
import gameTree4DA from "../../images/2_3_game-tree-4_DA.svg";
import spamOrHamDA from "../../images/3_3_spam-or-ham_DA.svg";
import chickenCrossing1DA from "../../images/2_1_chicken-crossing-1_DA.svg";
import chickenCrossing2DA from "../../images/2_1_chicken-crossing-2_DA.svg";
import chickenCrossing3DA from "../../images/2_1_chicken-crossing-3_DA.svg";
import chickenCrossing4DA from "../../images/2_1_chicken-crossing-4_DA.svg";
//ro specific
import bayesRule1RO from "../../images/3_2_Bayes-Rule_1_RO.svg";
import bayesRule2RO from "../../images/3_2_Bayes-Rule_2_RO.svg";
import nearestNeighborGraphRO from "../../images/4_2_nearest-neighbor_RO.svg";
import recommendationRO from "../../images/4_2_recommendation_RO.svg";
import definingAiRO from "../../images/1_1_defineai_RO.svg";
import search1RO from "../../images/2_1_search-1_RO.svg";
import search2RO from "../../images/2_1_search-2_RO.svg";
import gameTree4RO from "../../images/2_3_game-tree-4_RO.svg";
import spamOrHamRO from "../../images/3_3_spam-or-ham_RO.svg";
import chickenCrossing1RO from "../../images/2_1_chicken-crossing-1_RO.svg";
import chickenCrossing2RO from "../../images/2_1_chicken-crossing-2_RO.svg";
import chickenCrossing3RO from "../../images/2_1_chicken-crossing-3_RO.svg";
import chickenCrossing4RO from "../../images/2_1_chicken-crossing-4_RO.svg";

//is specific
import bayesRule1IS from "../../images/3_2_Bayes-Rule_1_IS.svg";
import bayesRule2IS from "../../images/3_2_Bayes-Rule_2_IS.svg";
import nearestNeighborGraphIS from "../../images/4_2_nearest-neighbor_IS.svg";
import recommendationIS from "../../images/4_2_recommendation_IS.svg";
import definingAiIS from "../../images/1_1_defineai_IS.svg";
import search1IS from "../../images/2_1_search-1_IS.svg";
import search2IS from "../../images/2_1_search-2_IS.svg";
import gameTree4IS from "../../images/2_3_game-tree-4_IS.svg";
import spamOrHamIS from "../../images/3_3_spam-or-ham_IS.svg";

//bg specific
import bayesRule1BG from '../../images/3_2_Bayes-Rule_1_BG.svg'
import bayesRule2BG from '../../images/3_2_Bayes-Rule_2_BG.svg'
import nearestNeighborGraphBG from '../../images/4_2_nearest-neighbor_BG.svg'
import recommendationBG from '../../images/4_2_recommendation_BG.svg'
import definingAiBG from '../../images/1_1_defineai_BG.svg'
import search1BG from '../../images/2_1_search-1_BG.svg'
import search2BG from '../../images/2_1_search-2_BG.svg'
import gameTree4BG from '../../images/2_3_game-tree-4_BG.svg'
import spamOrHamBG from '../../images/3_3_spam-or-ham_BG.svg'
import chickenCrossing1BG from '../../images/2_1_chicken-crossing-1_BG.svg'
import chickenCrossing2BG from '../../images/2_1_chicken-crossing-2_BG.svg'
import chickenCrossing3BG from '../../images/2_1_chicken-crossing-3_BG.svg'
import chickenCrossing4BG from '../../images/2_1_chicken-crossing-4_BG.svg'
//cs specific
import bayesRule1CS from '../../images/3_2_Bayes-Rule_1_CS.svg'
import bayesRule2CS from '../../images/3_2_Bayes-Rule_2_CS.svg'
import nearestNeighborGraphCS from '../../images/4_2_nearest-neighbor_CS.svg'
import recommendationCS from '../../images/4_2_recommendation_CS.svg'
import definingAiCS from '../../images/1_1_defineai_CS.svg'
import search1CS from '../../images/2_1_search-1_CS.svg'
import search2CS from '../../images/2_1_search-2_CS.svg'
import gameTree4CS from '../../images/2_3_game-tree-4_CS.svg'
import spamOrHamCS from '../../images/3_3_spam-or-ham_CS.svg'
import chickenCrossing1CS from '../../images/2_1_chicken-crossing-1_CS.svg'
import chickenCrossing2CS from '../../images/2_1_chicken-crossing-2_CS.svg'
import chickenCrossing3CS from '../../images/2_1_chicken-crossing-3_CS.svg'
import chickenCrossing4CS from '../../images/2_1_chicken-crossing-4_CS.svg'

//sl specific
import bayesRule1SL from '../../images/3_2_Bayes-Rule_1_SL.svg'
import bayesRule2SL from '../../images/3_2_Bayes-Rule_2_SL.svg'
import nearestNeighborGraphSL from '../../images/4_2_nearest-neighbor_SL.svg'
import recommendationSL from '../../images/4_2_recommendation_SL.svg'
import definingAiSL from '../../images/1_1_defineai_SL.svg'
import search1SL from '../../images/2_1_search-1_SL.svg'
import search2SL from '../../images/2_1_search-2_SL.svg'
import gameTree4SL from '../../images/2_3_game-tree-4_SL.svg'
import spamOrHamSL from '../../images/3_3_spam-or-ham_SL.svg'
import chickenCrossing1SL from '../../images/2_1_chicken-crossing-1_SL.svg'
import chickenCrossing2SL from '../../images/2_1_chicken-crossing-2_SL.svg'
import chickenCrossing3SL from '../../images/2_1_chicken-crossing-3_SL.svg'
import chickenCrossing4SL from '../../images/2_1_chicken-crossing-4_SL.svg'
//pt specific
import bayesRule1PT from '../../images/3_2_Bayes-Rule_1_PT.svg'
import bayesRule2PT from '../../images/3_2_Bayes-Rule_2_PT.svg'
import nearestNeighborGraphPT from '../../images/4_2_nearest-neighbor_PT.svg'
import recommendationPT from '../../images/4_2_recommendation_PT.svg'
import definingAiPT from '../../images/1_1_defineai_PT.svg'
import search1PT from '../../images/2_1_search-1_PT.svg'
import search2PT from '../../images/2_1_search-2_PT.svg'
import gameTree4PT from '../../images/2_3_game-tree-4_PT.svg'
import spamOrHamPT from '../../images/3_3_spam-or-ham_PT.svg'
import chickenCrossing1PT from '../../images/2_1_chicken-crossing-1_PT.svg'
import chickenCrossing2PT from '../../images/2_1_chicken-crossing-2_PT.svg'
import chickenCrossing3PT from '../../images/2_1_chicken-crossing-3_PT.svg'
import chickenCrossing4PT from '../../images/2_1_chicken-crossing-4_PT.svg'

//es specific
import bayesRule1ES from '../../images/3_2_Bayes-Rule_1_ES.svg'
import bayesRule2ES from '../../images/3_2_Bayes-Rule_2_ES.svg'
import nearestNeighborGraphES from '../../images/4_2_nearest-neighbor_ES.svg'
import recommendationES from '../../images/4_2_recommendation_ES.svg'
import definingAiES from '../../images/1_1_defineai_ES.svg'
import search1ES from '../../images/2_1_search-1_ES.svg'
import search2ES from '../../images/2_1_search-2_ES.svg'
import gameTree4ES from '../../images/2_3_game-tree-4_ES.svg'
import spamOrHamES from '../../images/3_3_spam-or-ham_ES.svg'
import chickenCrossing1ES from '../../images/2_1_chicken-crossing_1_ES.svg'
import chickenCrossing2ES from '../../images/2_1_chicken-crossing_2_ES.svg'
import chickenCrossing3ES from '../../images/2_1_chicken-crossing_3_ES.svg'
import chickenCrossing4ES from '../../images/2_1_chicken-crossing_4_ES.svg'
//el specific
import bayesRule1EL from '../../images/3_2_Bayes-Rule_1_EL.svg'
import bayesRule2EL from '../../images/3_2_Bayes-Rule_2_EL.svg'
import nearestNeighborGraphEL from '../../images/4_2_nearest-neighbor_EL.svg'
import recommendationEL from '../../images/4_2_recommendation_EL.svg'
import definingAiEL from '../../images/1_1_defineai_EL.svg'
import search1EL from '../../images/2_1_search-1_EL.svg'
import search2EL from '../../images/2_1_search-2_EL.svg'
import gameTree1EL from '../../images/2_3_game-tree-1_EL.svg'
import gameTree2EL from '../../images/2_3_game-tree-2_EL.svg'
import gameTree3EL from '../../images/2_3_game-tree-3_EL.svg'
import gameTree4EL from '../../images/2_3_game-tree-4_EL.svg'
import spamOrHamEL from '../../images/3_3_spam-or-ham_EL.svg'
import chickenCrossing1EL from '../../images/2_1_chicken-crossing-1_EL.svg'
import chickenCrossing2EL from '../../images/2_1_chicken-crossing-2_EL.svg'
import chickenCrossing3EL from '../../images/2_1_chicken-crossing-3_EL.svg'
import chickenCrossing4EL from '../../images/2_1_chicken-crossing-4_EL.svg'

const IllustrationWrapper = styled(Box)`
  position: relative;
  margin-bottom: 4rem;
  margin-top: 4rem;
  img {
    z-index: 5;
    position: relative;
    width: 100%;
    height: auto;
    display: block;
  }
`;

const ColorBox = styled(Box)`
  height: ${props => (props.totalheight ? props.totalheight : "100%")};
  width: 100%;
  bottom: ${props => (props.frombottom ? props.frombottom : "0")};
  position: absolute;
  z-index: 1;
`;
const Illustration = props => {
  const { motive, intl } = props;
  switch (motive) {
    case "example-1":
      return <img src={example1Url} alt={intl({ id: "alt.example-1" })} />;
    case "example-2":
      return <img src={example2Url} alt={intl({ id: "alt.example-2" })} />;
    case "example-3":
      return <img src={example3Url} alt={intl({ id: "alt.example-3" })} />;
    case "defining-ai":
      return <img src={definingAiUrl} alt={intl({ id: "alt.defining-ai" })} />;
    case "turing-test":
      return <img src={turingTestUrl} alt={intl({ id: "alt.turing-test" })} />;
    case "chinese-room":
      return (
        <img src={chineseRoomUrl} alt={intl({ id: "alt.chinese-room" })} />
      );
    case "chicken-crossing":
      return (
        <img
          src={chickenCrossingUrl}
          alt={intl({ id: "alt.chicken-crossing" })}
        />
      );
    case "a-to-b":
      return <img src={aToBUrl} alt={intl({ id: "alt.a-to-b" })} />;
    case "tic-tac-toe":
      return <img src={ticTacTowUrl} alt={intl({ id: "alt.tic-tac-toe" })} />;
    case "self-driving-car":
      return (
        <img
          src={selfDrivingCarUrl}
          alt={intl({ id: "alt.self-driving-car" })}
        />
      );
    case "spam-or-ham":
      return <img src={spamOrHamUrl} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "mnist":
      return <img src={mnistUrl} alt={intl({ id: "alt.mnist" })} />;
    case "nearest-neighbor":
      return (
        <img
          src={nearestNeighborUrl}
          alt={intl({ id: "alt.nearest-neighbor" })}
        />
      );
    case "nearest-neighbor-graph":
      return (
        <img
          src={nearestNeighborGraphUrl}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation":
      return (
        <img src={recommendationUrl} alt={intl({ id: "alt.recommendation" })} />
      );
    case "price-real-estate":
      return (
        <img
          src={priceRealEstateUrl}
          alt={intl({ id: "alt.price-real-estate" })}
        />
      );
    case "supervised-learning":
      return (
        <img
          src={supervisedLearningUrl}
          alt={intl({ id: "alt.supervised-learning" })}
        />
      );
    case "dice":
      return <img src={diceUrl} alt={intl({ id: "alt.dice" })} />;
    case "chess":
      return <img src={chessUrl} alt={intl({ id: "alt.chess" })} />;
    case "poker":
      return <img src={pokerUrl} alt={intl({ id: "alt.poker" })} />;
    case "eyes":
      return <img src={eyesUrl} alt={intl({ id: "alt.eyes" })} />;
    case "search-1":
      return <img src={search1Url} alt={intl({ id: "alt.search-1" })} />;
    case "search-2":
      return <img src={search2Url} alt={intl({ id: "alt.search-2" })} />;
    case "chicken-crossing-1":
      return (
        <img
          src={chickenCrossing1Url}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2":
      return (
        <img
          src={chickenCrossing2Url}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3":
      return (
        <img
          src={chickenCrossing3Url}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4":
      return (
        <img
          src={chickenCrossing4Url}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    case "chicken-crossing-5":
      return (
        <img
          src={chickenCrossing5Url}
          alt={intl({ id: "alt.chicken-crossing-5" })}
        />
      );
    case "implication-1":
      return (
        <img src={implication1Url} alt={intl({ id: "alt.implication-1" })} />
      );
    case "implication-2":
      return (
        <img src={implication2Url} alt={intl({ id: "alt.implication-2" })} />
      );
    case "implication-3":
      return (
        <img src={implication3Url} alt={intl({ id: "alt.implication-3" })} />
      );
    case "implication-4":
      return (
        <img src={implication4Url} alt={intl({ id: "alt.implication-4" })} />
      );
    case "oddchange":
      return <img src={oddchangeUrl} alt={intl({ id: "alt.oddchange" })} />;
    case "brain":
      return <img src={brainUrl} alt={intl({ id: "alt.brain" })} />;
    case "carstop":
      return <img src={carstopUrl} alt={intl({ id: "alt.carstop" })} />;
    case "gan":
      return <img src={ganUrl} alt={intl({ id: "alt.gan" })} />;
    case "hammock":
      return <img src={hammockUrl} alt={intl({ id: "alt.hammock" })} />;
    case "game-tree-1":
      return <img src={gameTree1Url} alt={intl({ id: "alt.game-tree-1" })} />;
    case "game-tree-2":
      return <img src={gameTree2Url} alt={intl({ id: "alt.game-tree-2" })} />;
    case "game-tree-3":
      return <img src={gameTree3Url} alt={intl({ id: "alt.game-tree-3" })} />;
    case "game-tree-4":
      return <img src={gameTree4Url} alt={intl({ id: "alt.game-tree-4" })} />;
    case "bayes-rule-1":
      return <img src={bayesRule1Url} alt={intl({ id: "alt.bayes-rule-1" })} />;
    case "bayes-rule-2":
      return <img src={bayesRule2Url} alt={intl({ id: "alt.bayes-rule-2" })} />;
    case "defineai":
      return <img src={definedaiUrl} alt={intl({ id: "alt.defineai" })} />;
    case "winter":
      return <img src={winterUrl} alt={intl({ id: "alt.winter" })} />;
    case "terminator":
      return <img src={terminatorUrl} alt={intl({ id: "alt.terminator" })} />;
    //FINNISH
    case "definingaifi":
      return <img src={definingAiFI} alt={intl({ id: "alt.defining-ai" })} />;
    case "chickencrossing2urlfi":
      return (
        <img
          src={chickenCrossing2UrlFI}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chickencrossing3urlfi":
      return (
        <img
          src={chickenCrossing3UrlFI}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chickencrossing4urlfi":
      return (
        <img
          src={chickenCrossing4UrlFI}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chickencrossing5urlfi":
      return (
        <img
          src={chickenCrossing5UrlFI}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    case "bayesrule1urlfi":
      return (
        <img src={bayesRule1UrlFI} alt={intl({ id: "alt.bayes-rule-1" })} />
      );
    case "bayesrule2urlfi":
      return (
        <img src={bayesRule2UrlFI} alt={intl({ id: "alt.bayes-rule-2" })} />
      );
    case "nearestneighborurlfi":
      return (
        <img
          src={nearestNeighborUrlFI}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    //SWEDISH
    case "example2se":
      return <img src={example2SE} alt={intl({ id: "alt.example-2" })} />;
    case "search1se":
      return <img src={search1SE} alt={intl({ id: "alt.search-1" })} />;
    case "search2se":
      return <img src={search2SE} alt={intl({ id: "alt.search-2" })} />;
    case "spamorhamse":
      return <img src={spamOrHamSE} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "definingaise":
      return <img src={definingAiSE} alt={intl({ id: "alt.defining-ai" })} />;
    case "chickencrossing2urlse":
      return (
        <img
          src={chickenCrossing2UrlSE}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chickencrossing3urlse":
      return (
        <img
          src={chickenCrossing3UrlSE}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chickencrossing4urlse":
      return (
        <img
          src={chickenCrossing4UrlSE}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chickencrossing5urlse":
      return (
        <img
          src={chickenCrossing5UrlSE}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    case "bayesrule1urlse":
      return (
        <img src={bayesRule1UrlSE} alt={intl({ id: "alt.bayes-rule-1" })} />
      );
    case "bayesrule2urlse":
      return (
        <img src={bayesRule2UrlSE} alt={intl({ id: "alt.bayes-rule-2" })} />
      );
    case "nearestneighborurlse":
      return (
        <img
          src={nearestNeighborUrlSE}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    //GERMAN
    case "game-tree-4-de":
      return <img src={gameTree4UrlDe} alt={intl({ id: "alt.game-tree-4" })} />;
    case "nearest-neighbor-de":
      return (
        <img
          src={nearestNeighborUrl}
          alt={intl({ id: "alt.nearest-neighbor" })}
        />
      );
    case "nearest-neighbor-graph-de":
      return (
        <img
          src={nearestNeighborUrlDE}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-de":
      return (
        <img
          src={recommendationUrlDE}
          alt={intl({ id: "alt.recommendation" })}
        />
      );
    case "defineai-de":
      return <img src={definingAiDE} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-de":
      return <img src={search1DE} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-de":
      return <img src={search2DE} alt={intl({ id: "alt.search-2" })} />;
    case "spam-or-ham-de":
      return <img src={spamOrHamDE} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "bayes-rule-1-de":
      return (
        <img src={bayesRule1UrlDE} alt={intl({ id: "alt.bayes-rule-1" })} />
      );
    case "bayes-rule-2-de":
      return (
        <img src={bayesRule2UrlDE} alt={intl({ id: "alt.bayes-rule-2" })} />
      );
    case "chicken-crossing-1-de":
      return (
        <img
          src={chickenCrossing1UrlDE}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-de":
      return (
        <img
          src={chickenCrossing2UrlDE}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-de":
      return (
        <img
          src={chickenCrossing3UrlDE}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-de":
      return (
        <img
          src={chickenCrossing4UrlDE}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    //ESTONIAN
    case "game-tree-4-ee":
      return <img src={gameTree4UrlEE} alt={intl({ id: "alt.game-tree-4" })} />;
    case "nearest-neighbor-ee":
      return (
        <img
          src={nearestNeighborUrl}
          alt={intl({ id: "alt.nearest-neighbor" })}
        />
      );
    case "nearest-neighbor-graph-ee":
      return (
        <img
          src={nearestNeighborUrlEE}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-ee":
      return (
        <img
          src={recommendationUrlEE}
          alt={intl({ id: "alt.recommendation" })}
        />
      );
    case "defineai-ee":
      return <img src={definingAiEE} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-ee":
      return <img src={search1EE} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-ee":
      return <img src={search2EE} alt={intl({ id: "alt.search-2" })} />;
    case "spam-or-ham-ee":
      return <img src={spamOrHamEE} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "bayes-rule-1-ee":
      return (
        <img src={bayesRule1UrlEE} alt={intl({ id: "alt.bayes-rule-1" })} />
      );
    case "bayes-rule-2-ee":
      return (
        <img src={bayesRule2UrlEE} alt={intl({ id: "alt.bayes-rule-2" })} />
      );
    case "chicken-crossing-1-ee":
      return (
        <img
          src={chickenCrossing1UrlEE}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-ee":
      return (
        <img
          src={chickenCrossing2UrlEE}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-ee":
      return (
        <img
          src={chickenCrossing3UrlEE}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-ee":
      return (
        <img
          src={chickenCrossing4UrlEE}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    case "towersOfHanoiExercise-ee":
      return <img src={towersOfHanoiEE} alt="Towers of Hanoi" />;

    //Lithuanian
    case "game-tree-4-LT":
      return <img src={gameTree4LT} alt={intl({ id: "alt.game-tree-4" })} />;
    case "nearest-neighbor-graph-LT":
      return (
        <img
          src={nearestNeighborGraphLT}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-LT":
      return (
        <img src={recommendationLT} alt={intl({ id: "alt.recommendation" })} />
      );
    case "defineai-LT":
      return <img src={definingAiLT} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-LT":
      return <img src={search1LT} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-LT":
      return <img src={search2LT} alt={intl({ id: "alt.search-2" })} />;
    case "spam-or-ham-LT":
      return <img src={spamOrHamLT} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "bayes-rule-1-LT":
      return <img src={bayesRule1LT} alt={intl({ id: "alt.bayes-rule-1" })} />;
    case "bayes-rule-2-LT":
      return <img src={bayesRule2LT} alt={intl({ id: "alt.bayes-rule-2" })} />;
    case "chicken-crossing-1-LT":
      return (
        <img
          src={chickenCrossing1LT}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-LT":
      return (
        <img
          src={chickenCrossing2LT}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-LT":
      return (
        <img
          src={chickenCrossing3LT}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-LT":
      return (
        <img
          src={chickenCrossing4LT}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    //NB
    case "defineai-no":
      return <img src={definingAiNB} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-no":
      return <img src={searchOneNB} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-no":
      return <img src={searchTwoNB} alt={intl({ id: "alt.search-2" })} />;
    case "chicken-crossing-1-no":
      return (
        <img
          src={chickenCrossing1NB}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-no":
      return (
        <img
          src={chickenCrossing2NB}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-no":
      return (
        <img
          src={chickenCrossing3NB}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-no":
      return (
        <img
          src={chickenCrossing4NB}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    case "game-tree-4-no":
      return <img src={gameTree4} alt={intl({ id: "alt.game-tree-4" })} />;
    case "bayes-rule-1-no":
      return <img src={bayesRule1} alt={intl({ id: "alt.bayes-rule-1" })} />;
    case "bayes-rule-2-no":
      return <img src={bayesRule2} alt={intl({ id: "alt.bayes-rule-2" })} />;
    case "spam-or-ham-no":
      return <img src={spamOrHamNB} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "nearest-neighbor-graph-no":
      return (
        <img
          src={nearestNeighborNB}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-no":
      return (
        <img src={recommendationNB} alt={intl({ id: "alt.recommendation" })} />
      );
    //FRENCH
    case "defineai-fr":
      return <img src={definingAiFR} alt={intl({ id: "alt.defining-ai" })} />;
    case "spam-or-ham-fr":
      return <img src={spamOrHamFR} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "nearest-neighbor-graph-fr":
      return (
        <img
          src={nearestNeighborUrlFR}
          alt={intl({ id: "alt.nearest-neighbor" })}
        />
      );
    case "recommendation-fr":
      return (
        <img
          src={recommendationUrlFR}
          alt={intl({ id: "alt.recommendation" })}
        />
      );
    case "search-1-fr":
      return <img src={search1FR} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-fr":
      return <img src={search2FR} alt={intl({ id: "alt.search-2" })} />;
    case "chicken-crossing-1-fr":
      return (
        <img
          src={chickenCrossing1UrlFR}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-fr":
      return (
        <img
          src={chickenCrossing2UrlFR}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-fr":
      return (
        <img
          src={chickenCrossing3UrlFR}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-fr":
      return (
        <img
          src={chickenCrossing4UrlFR}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    case "game-tree-4-fr":
      return <img src={gameTree4UrlFR} alt={intl({ id: "alt.game-tree-4" })} />;
    case "bayes-rule-1-fr":
      return (
        <img src={bayesRule1UrlFR} alt={intl({ id: "alt.bayes-rule-1" })} />
      );
    case "bayes-rule-2-fr":
      return (
        <img src={bayesRule2UrlFR} alt={intl({ id: "alt.bayes-rule-2" })} />
      );
    case "towersOfHanoiExercise-fr":
      return <img src={towersOfHanoiFR} alt="Towers of Hanoi" />;
    //Latvian
    case "game-tree-4-lv":
      return <img src={gameTree4LV} alt={intl({ id: "alt.game-tree-4" })} />;
    case "nearest-neighbor-graph-lv":
      return (
        <img
          src={nearestNeighborGraphLV}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-lv":
      return (
        <img src={recommendationLV} alt={intl({ id: "alt.recommendation" })} />
      );
    case "defineai-lv":
      return <img src={definingAiLV} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-lv":
      return <img src={search1LV} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-lv":
      return <img src={search2LV} alt={intl({ id: "alt.search-2" })} />;
    case "spam-or-ham-lv":
      return <img src={spamOrHamLV} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "bayes-rule-1-lv":
      return <img src={bayesRule1LV} alt={intl({ id: "alt.bayes-rule-1" })} />;
    case "bayes-rule-2-lv":
      return <img src={bayesRule2LV} alt={intl({ id: "alt.bayes-rule-2" })} />;
    case "chicken-crossing-1-lv":
      return (
        <img
          src={chickenCrossing1LV}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-lv":
      return (
        <img
          src={chickenCrossing2LV}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-lv":
      return (
        <img
          src={chickenCrossing3LV}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-lv":
      return (
        <img
          src={chickenCrossing4LV}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );

    //Dutch
    case "game-tree-4-NL":
      return <img src={gameTree4NL} alt={intl({ id: "alt.game-tree-4" })} />;
    case "nearest-neighbor-graph-NL":
      return (
        <img
          src={nearestNeighborGraphNL}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-NL":
      return (
        <img src={recommendationNL} alt={intl({ id: "alt.recommendation" })} />
      );
    case "defineai-NL":
      return <img src={definingAiNL} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-NL":
      return <img src={search1NL} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-NL":
      return <img src={search2NL} alt={intl({ id: "alt.search-2" })} />;
    case "spam-or-ham-NL":
      return <img src={spamOrHamNL} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "bayes-rule-1-NL":
      return <img src={bayesRule1NL} alt={intl({ id: "alt.bayes-rule-1" })} />;
    case "bayes-rule-2-NL":
      return <img src={bayesRule2NL} alt={intl({ id: "alt.bayes-rule-2" })} />;
    case "chicken-crossing-1-NL":
      return (
        <img
          src={chickenCrossing1NL}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-NL":
      return (
        <img
          src={chickenCrossing2NL}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-NL":
      return (
        <img
          src={chickenCrossing3NL}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-NL":
      return (
        <img
          src={chickenCrossing4NL}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    //Italian
    case "game-tree-4-it":
      return <img src={gameTree4UrlIT} alt={intl({ id: "alt.game-tree-4" })} />;
    case "nearest-neighbor-it":
      return (
        <img
          src={nearestNeighborUrl}
          alt={intl({ id: "alt.nearest-neighbor" })}
        />
      );
    case "nearest-neighbor-graph-it":
      return (
        <img
          src={nearestNeighborUrlIT}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-it":
      return (
        <img
          src={recommendationUrlIT}
          alt={intl({ id: "alt.recommendation" })}
        />
      );
    case "defineai-it":
      return <img src={definingAiIT} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-it":
      return <img src={search1IT} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-it":
      return <img src={search2IT} alt={intl({ id: "alt.search-2" })} />;
    case "spam-or-ham-it":
      return <img src={spamOrHamIT} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "bayes-rule-1-it":
      return (
        <img src={bayesRule1UrlIT} alt={intl({ id: "alt.bayes-rule-1" })} />
      );
    case "bayes-rule-2-it":
      return (
        <img src={bayesRule2UrlIT} alt={intl({ id: "alt.bayes-rule-2" })} />
      );
    case "chicken-crossing-1-it":
      return (
        <img
          src={chickenCrossing1UrlIT}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-it":
      return (
        <img
          src={chickenCrossing2UrlIT}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-it":
      return (
        <img
          src={chickenCrossing3UrlIT}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-it":
      return (
        <img
          src={chickenCrossing4UrlIT}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      );
    case "towersOfHanoiExercise-it":
      return <img src={towersOfHanoiIT} alt="Towers of Hanoi" />;
    //Maltese
    case "game-tree-4-MT":
      return <img src={gameTree4MT} alt={intl({ id: "alt.game-tree-4" })} />;
    case "nearest-neighbor-graph-MT":
      return (
        <img
          src={nearestNeighborGraphMT}
          alt={intl({ id: "alt.nearest-neighbor-graph" })}
        />
      );
    case "recommendation-MT":
      return (
        <img src={recommendationMT} alt={intl({ id: "alt.recommendation" })} />
      );
    case "defineai-MT":
      return <img src={definingAiMT} alt={intl({ id: "alt.defining-ai" })} />;
    case "search-1-MT":
      return <img src={search1MT} alt={intl({ id: "alt.search-1" })} />;
    case "search-2-MT":
      return <img src={search2MT} alt={intl({ id: "alt.search-2" })} />;
    case "spam-or-ham-MT":
      return <img src={spamOrHamMT} alt={intl({ id: "alt.spam-or-ham" })} />;
    case "bayes-rule-1-MT":
      return <img src={bayesRule1MT} alt={intl({ id: "alt.bayes-rule-1" })} />;
    case "bayes-rule-2-MT":
      return <img src={bayesRule2MT} alt={intl({ id: "alt.bayes-rule-2" })} />;
    case "chicken-crossing-1-MT":
      return (
        <img
          src={chickenCrossing1MT}
          alt={intl({ id: "alt.chicken-crossing-1" })}
        />
      );
    case "chicken-crossing-2-MT":
      return (
        <img
          src={chickenCrossing2MT}
          alt={intl({ id: "alt.chicken-crossing-2" })}
        />
      );
    case "chicken-crossing-3-MT":
      return (
        <img
          src={chickenCrossing3MT}
          alt={intl({ id: "alt.chicken-crossing-3" })}
        />
      );
    case "chicken-crossing-4-MT":
      return (
        <img
          src={chickenCrossing4MT}
          alt={intl({ id: "alt.chicken-crossing-4" })}
        />
      )
    //Croatian
    case "game-tree-4-HR":
      return <img src={gameTree4HR} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-HR':
      return (
        <img
          src={nearestNeighborGraphHR}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case "recommendation-HR":
      return (
        <img src={recommendationHR} alt={intl({ id: 'alt.recommendation' })} />
      )
    case "defineai-HR":
      return <img src={definingAiHR} alt={intl({ id: 'alt.defining-ai' })} />
    case "search-1-HR":
      return <img src={search1HR} alt={intl({ id: 'alt.search-1' })} />
    case "search-2-HR":
      return <img src={search2HR} alt={intl({ id: 'alt.search-2' })} />
    case "spam-or-ham-HR":
      return <img src={spamOrHamHR} alt={intl({ id: 'alt.spam-or-ham' })} />
    case "bayes-rule-1-HR":
      return <img src={bayesRule1HR} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case "bayes-rule-2-HR":
      return <img src={bayesRule2HR} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case "chicken-crossing-1-HR":
      return (
        <img
          src={chickenCrossing1HR}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case "chicken-crossing-2-HR":
      return (
        <img
          src={chickenCrossing2HR}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case "chicken-crossing-3-HR":
      return (
        <img
          src={chickenCrossing3HR}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case "chicken-crossing-4-HR":
      return (
        <img
          src={chickenCrossing4HR}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Polish
    case 'game-tree-4-PL':
      return <img src={gameTree4PL} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-PL':
      return (
        <img
          src={nearestNeighborGraphPL}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-PL':
      return (
        <img src={recommendationPL} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-PL':
      return <img src={definingAiPL} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-PL':
      return <img src={search1PL} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-PL':
      return <img src={search2PL} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-PL':
      return <img src={spamOrHamPL} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-PL':
      return <img src={bayesRule1PL} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-PL':
      return <img src={bayesRule2PL} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-PL':
      return (
        <img
          src={chickenCrossing1PL}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-PL':
      return (
        <img
          src={chickenCrossing2PL}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-PL':
      return (
        <img
          src={chickenCrossing3PL}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-PL':
      return (
        <img
          src={chickenCrossing4PL}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )

    // Irish
    case 'game-tree-4-GA':
      return <img src={gameTree4GA} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-GA':
      return (
        <img
          src={nearestNeighborGraphGA}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-GA':
      return (
        <img src={recommendationGA} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-GA':
      return <img src={definingAiGA} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-GA':
      return <img src={search1GA} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-GA':
      return <img src={search2GA} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-GA':
      return <img src={spamOrHamGA} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-GA':
      return <img src={bayesRule1GA} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-GA':
      return <img src={bayesRule2GA} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-GA':
      return (
        <img
          src={chickenCrossing1GA}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-GA':
      return (
        <img
          src={chickenCrossing2GA}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-GA':
      return (
        <img
          src={chickenCrossing3GA}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-GA':
      return (
        <img
          src={chickenCrossing4GA}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Dutch
    case 'game-tree-4-NL':
      return <img src={gameTree4NL} alt={intl({ id: "alt.game-tree-4" })} />;
    case 'nearest-neighbor-graph-NL':
      return <img src={nearestNeighborGraphNL} alt={intl({ id: 'alt.nearest-neighbor-graph' })} />;
    case 'recommendation-NL':
      return <img src={recommendationNL} alt={intl({ id: "alt.recommendation" })} />;
    case 'defineai-NL':
      return <img src={definingAiNL} alt={intl({ id: "alt.defining-ai" })} />;
    case 'search-1-NL':
      return <img src={search1NL} alt={intl({ id: "alt.search-1" })} />;
    case 'search-2-NL':
      return <img src={search2NL} alt={intl({ id: "alt.search-2" })} />;
    case 'spam-or-ham-NL':
      return <img src={spamOrHamNL} alt={intl({ id: "alt.spam-or-ham" })} />;
    case 'bayes-rule-1-NL':
      return <img src={bayesRule1NL} alt={intl({ id: "alt.bayes-rule-1" })} />;
    case 'bayes-rule-2-NL':
      return <img src={bayesRule2NL} alt={intl({ id: "alt.bayes-rule-2" })} />;
    case 'chicken-crossing-1-NL':
      return <img src={chickenCrossing1NL} alt={intl({ id: "alt.chicken-crossing-1" })} />;
    case 'chicken-crossing-2-NL':
      return <img src={chickenCrossing2NL} alt={intl({ id: "alt.chicken-crossing-2" })} />;
    case 'chicken-crossing-3-NL':
      return <img src={chickenCrossing3NL} alt={intl({ id: "alt.chicken-crossing-3" })} />;
    case 'chicken-crossing-4-NL':
      return <img src={chickenCrossing4NL} alt={intl({ id: "alt.chicken-crossing-4" })} />;
    //Slovak
    case 'game-tree-4-SK':
      return <img src={gameTree4SK} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-SK':
      return (
        <img
          src={nearestNeighborGraphSK}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-SK':
      return (
        <img src={recommendationSK} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-SK':
      return <img src={definingAiSK} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-SK':
      return <img src={search1SK} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-SK':
      return <img src={search2SK} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-SK':
      return <img src={spamOrHamSK} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-SK':
      return <img src={bayesRule1SK} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-SK':
      return <img src={bayesRule2SK} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-SK':
      return (
        <img
          src={chickenCrossing1SK}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-SK':
      return (
        <img
          src={chickenCrossing2SK}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-SK':
      return (
        <img
          src={chickenCrossing3SK}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-SK':
      return (
        <img
          src={chickenCrossing4SK}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Danish
    case 'game-tree-4-DA':
      return <img src={gameTree4DA} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-DA':
      return (
        <img
          src={nearestNeighborGraphDA}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-DA':
      return (
        <img src={recommendationDA} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-DA':
      return <img src={definingAiDA} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-DA':
      return <img src={search1DA} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-DA':
      return <img src={search2DA} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-DA':
      return <img src={spamOrHamDA} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-DA':
      return <img src={bayesRule1DA} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-DA':
      return <img src={bayesRule2DA} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-DA':
      return (
        <img
          src={chickenCrossing1DA}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-DA':
      return (
        <img
          src={chickenCrossing2DA}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-DA':
      return (
        <img
          src={chickenCrossing3DA}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-DA':
      return (
        <img
          src={chickenCrossing4DA}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Romanian
    case 'game-tree-4-RO':
      return <img src={gameTree4RO} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-RO':
      return (
        <img
          src={nearestNeighborGraphRO}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-RO':
      return (
        <img src={recommendationRO} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-RO':
      return <img src={definingAiRO} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-RO':
      return <img src={search1RO} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-RO':
      return <img src={search2RO} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-RO':
      return <img src={spamOrHamRO} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-RO':
      return <img src={bayesRule1RO} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-RO':
      return <img src={bayesRule2RO} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-RO':
      return (
        <img
          src={chickenCrossing1RO}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-RO':
      return (
        <img
          src={chickenCrossing2RO}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-RO':
      return (
        <img
          src={chickenCrossing3RO}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-RO':
      return (
        <img
          src={chickenCrossing4RO}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Icelandic
    case 'game-tree-4-IS':
      return <img src={gameTree4IS} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-IS':
      return (
        <img
          src={nearestNeighborGraphIS}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-IS':
      return (
        <img src={recommendationIS} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-IS':
      return <img src={definingAiIS} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-IS':
      return <img src={search1IS} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-IS':
      return <img src={search2IS} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-IS':
      return <img src={spamOrHamIS} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-IS':
      return <img src={bayesRule1IS} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-IS':
      return <img src={bayesRule2IS} alt={intl({ id: 'alt.bayes-rule-2' })} />

    //Bulgarian
    case 'game-tree-4-BG':
      return <img src={gameTree4BG} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-BG':
      return (
        <img
          src={nearestNeighborGraphBG}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-BG':
      return (
        <img src={recommendationBG} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-BG':
      return <img src={definingAiBG} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-BG':
      return <img src={search1BG} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-BG':
      return <img src={search2BG} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-BG':
      return <img src={spamOrHamBG} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-BG':
      return <img src={bayesRule1BG} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-BG':
      return <img src={bayesRule2BG} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-BG':
      return (
        <img
          src={chickenCrossing1BG}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-BG':
      return (
        <img
          src={chickenCrossing2BG}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-BG':
      return (
        <img
          src={chickenCrossing3BG}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-BG':
      return (
        <img
          src={chickenCrossing4BG}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Czech
    case 'game-tree-4-CS':
      return <img src={gameTree4CS} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-CS':
      return (
        <img
          src={nearestNeighborGraphCS}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-CS':
      return (
        <img src={recommendationCS} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-CS':
      return <img src={definingAiCS} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-CS':
      return <img src={search1CS} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-CS':
      return <img src={search2CS} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-CS':
      return <img src={spamOrHamCS} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-CS':
      return <img src={bayesRule1CS} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-CS':
      return <img src={bayesRule2CS} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-CS':
      return (
        <img
          src={chickenCrossing1CS}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-CS':
      return (
        <img
          src={chickenCrossing2CS}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-CS':
      return (
        <img
          src={chickenCrossing3CS}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-CS':
      return (
        <img
          src={chickenCrossing4CS}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Slovenian
    case 'game-tree-4-SL':
      return <img src={gameTree4SL} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-SL':
      return (
        <img
          src={nearestNeighborGraphSL}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-SL':
      return (
        <img src={recommendationSL} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-SL':
      return <img src={definingAiSL} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-SL':
      return <img src={search1SL} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-SL':
      return <img src={search2SL} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-SL':
      return <img src={spamOrHamSL} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-SL':
      return <img src={bayesRule1SL} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-SL':
      return <img src={bayesRule2SL} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-SL':
      return (
        <img
          src={chickenCrossing1SL}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-SL':
      return (
        <img
          src={chickenCrossing2SL}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-SL':
      return (
        <img
          src={chickenCrossing3SL}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-SL':
      return (
        <img
          src={chickenCrossing4SL}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Portuguese
    case 'game-tree-4-PT':
      return <img src={gameTree4PT} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-PT':
      return (
        <img
          src={nearestNeighborGraphPT}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-PT':
      return (
        <img src={recommendationPT} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-PT':
      return <img src={definingAiPT} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-PT':
      return <img src={search1PT} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-PT':
      return <img src={search2PT} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-PT':
      return <img src={spamOrHamPT} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-PT':
      return <img src={bayesRule1PT} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-PT':
      return <img src={bayesRule2PT} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-PT':
      return (
        <img
          src={chickenCrossing1PT}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-PT':
      return (
        <img
          src={chickenCrossing2PT}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-PT':
      return (
        <img
          src={chickenCrossing3PT}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-PT':
      return (
        <img
          src={chickenCrossing4PT}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Spanish
    case 'game-tree-4-ES':
      return <img src={gameTree4ES} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-ES':
      return (
        <img
          src={nearestNeighborGraphES}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-ES':
      return (
        <img src={recommendationES} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-ES':
      return <img src={definingAiES} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-ES':
      return <img src={search1ES} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-ES':
      return <img src={search2ES} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-ES':
      return <img src={spamOrHamES} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-ES':
      return <img src={bayesRule1ES} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-ES':
      return <img src={bayesRule2ES} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-ES':
      return (
        <img
          src={chickenCrossing1ES}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-ES':
      return (
        <img
          src={chickenCrossing2ES}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-ES':
      return (
        <img
          src={chickenCrossing3ES}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-ES':
      return (
        <img
          src={chickenCrossing4ES}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    //Greek
    case 'game-tree-1-EL':
      return <img src={gameTree1EL} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'game-tree-2-EL':
      return <img src={gameTree2EL} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'game-tree-3-EL':
      return <img src={gameTree3EL} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'game-tree-4-EL':
      return <img src={gameTree4EL} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-EL':
      return (
        <img
          src={nearestNeighborGraphEL}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-EL':
      return (
        <img src={recommendationEL} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-EL':
      return <img src={definingAiEL} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-EL':
      return <img src={search1EL} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-EL':
      return <img src={search2EL} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-EL':
      return <img src={spamOrHamEL} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-EL':
      return <img src={bayesRule1EL} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-EL':
      return <img src={bayesRule2EL} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-EL':
      return (
        <img
          src={chickenCrossing1EL}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-EL':
      return (
        <img
          src={chickenCrossing2EL}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-EL':
      return (
        <img
          src={chickenCrossing3EL}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-EL':
      return (
        <img
          src={chickenCrossing4EL}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )

    // Irish
    case 'game-tree-4-GA':
      return <img src={gameTree4GA} alt={intl({ id: 'alt.game-tree-4' })} />
    case 'nearest-neighbor-graph-GA':
      return (
        <img
          src={nearestNeighborGraphGA}
          alt={intl({ id: 'alt.nearest-neighbor-graph' })}
        />
      )
    case 'recommendation-GA':
      return (
        <img src={recommendationGA} alt={intl({ id: 'alt.recommendation' })} />
      )
    case 'defineai-GA':
      return <img src={definingAiGA} alt={intl({ id: 'alt.defining-ai' })} />
    case 'search-1-GA':
      return <img src={search1GA} alt={intl({ id: 'alt.search-1' })} />
    case 'search-2-GA':
      return <img src={search2GA} alt={intl({ id: 'alt.search-2' })} />
    case 'spam-or-ham-GA':
      return <img src={spamOrHamGA} alt={intl({ id: 'alt.spam-or-ham' })} />
    case 'bayes-rule-1-GA':
      return <img src={bayesRule1GA} alt={intl({ id: 'alt.bayes-rule-1' })} />
    case 'bayes-rule-2-GA':
      return <img src={bayesRule2GA} alt={intl({ id: 'alt.bayes-rule-2' })} />
    case 'chicken-crossing-1-GA':
      return (
        <img
          src={chickenCrossing1GA}
          alt={intl({ id: 'alt.chicken-crossing-1' })}
        />
      )
    case 'chicken-crossing-2-GA':
      return (
        <img
          src={chickenCrossing2GA}
          alt={intl({ id: 'alt.chicken-crossing-2' })}
        />
      )
    case 'chicken-crossing-3-GA':
      return (
        <img
          src={chickenCrossing3GA}
          alt={intl({ id: 'alt.chicken-crossing-3' })}
        />
      )
    case 'chicken-crossing-4-GA':
      return (
        <img
          src={chickenCrossing4GA}
          alt={intl({ id: 'alt.chicken-crossing-4' })}
        />
      )
    default:
      return null;
  }
};

const Illustrations = props => {
  const { motive, color, frombottom, totalheight, intl } = props;

  return (
    <IllustrationWrapper>
      <SmallContainer>
        <Illustration motive={motive} intl={intl.formatMessage} />
      </SmallContainer>
      {color ? (
        <ColorBox
          bg={color}
          frombottom={frombottom}
          totalheight={totalheight}
        />
      ) : (
        ""
      )}
    </IllustrationWrapper>
  );
};

export default injectIntl(Illustrations);
