import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import Text from "../Text";
import TextF4 from "../TextF4";
import DefaultContainer from "./DefaultContainer";

const Ul = styled.ul`
  padding: 0;
  font-size: 1.125rem;
  color: #29264c;
  padding-left: 20px;
  margin-bottom: 1rem;
  margin-top: 0;
  li {
    padding: 0 0 1rem 0;
    line-height: 1.8;
    font-family: ${themeGet("fonts.alkesRegular")};
  }
  a {
    color: #9c329c;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
`;

const MarkdownUl = props => {
  const { ...rest } = props;
  return (
    <DefaultContainer>
      <Ul {...rest} />
    </DefaultContainer>
  );
};

export default MarkdownUl;
