import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { themeGet } from "styled-system";
import LogisticRegressionChart from "../../components/Visualizations/LogisticRegressionChart";
import DefaultContainer from "./DefaultContainer";
import _ from "lodash";

const ValueContainer = styled.div`
  display: inline-block;
  width: 120px;
  text-align: center;
`;

const ControllersContainer = styled.div`
  text-align: center;
`;

const ControllerContainer = styled.div`
  display: inline-block;
  margin: 5px 20px;
`;

const Button = styled.button`
  padding: 0.1rem 0.6rem;
  color: white;
  background: #4844a3;
  border: 2px solid #4844a3;
  border-radius: 2rem;
  margin-top: 7px;
  font-size: 1.5rem;
  font-family: ${themeGet("fonts.TTNormsProBold")};
  box-shadow: 0 0 4px 3px rgba(0, 0, 0, 0.04);
  outline: none;
  :hover {
    cursor: pointer;
  }
`;

const ControllerText = styled.div`
  color: #29264c;
  line-height: 1.5;
  font-family: "walsheim-bold", "tt-norms-pro-bold", "sans-serif";
  margin: 10px;
  font-size: 1rem;
  display: inline-block;
`;

const nextValue = (currentValue, values) => {
  const index = _.indexOf(values, currentValue);
  return index + 1 < values.length ? values[index + 1] : currentValue;
};

const previousValue = (currentValue, values) => {
  const index = _.indexOf(values, currentValue);
  return index - 1 >= 0 ? values[index - 1] : currentValue;
};

export default class LinearRegressionExercise4 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        { x: 14.62562917, y: 1.0 },
        { x: 11.83430826, y: 1.0 },
        { x: 15.16224832, y: 1.0 },
        { x: 12.58546897, y: 1.0 },
        { x: 13.11403893, y: 1.0 },
        { x: 14.2706756, y: 1.0 },
        { x: 12.87650042, y: 1.0 },
        { x: 10.32092847, y: 1.0 },
        { x: 13.96015141, y: 1.0 },
        { x: 9.69321136, y: 1.0 },
        { x: 12.83833043, y: 1.0 },
        { x: 13.35893525, y: 1.0 },
        { x: 10.22716705, y: 1.0 },
        { x: 9.66679189, y: 1.0 },
        { x: 12.7635011, y: 1.0 },
        { x: 9.94524671, y: 1.0 },
        { x: 9.98108602, y: 1.0 },
        { x: 12.18886972, y: 1.0 },
        { x: 10.56556885, y: 1.0 },
        { x: 14.22196286, y: 1.0 },
        { x: 12.47837464, y: 1.0 },
        { x: 15.744123, y: 1.0 },
        { x: 14.57571601, y: 1.0 },
        { x: 15.5169715, y: 1.0 },
        { x: 10.5036877, y: 1.0 },
        { x: 10.08182354, y: 1.0 },
        { x: 8.4840712, y: 1.0 },
        { x: 9.20631184, y: 1.0 },
        { x: 12.33525853, y: 1.0 },
        { x: 12.25625575, y: 1.0 },
        { x: 13.38594038, y: 1.0 },
        { x: 13.20890212, y: 1.0 },
        { x: 14.67290973, y: 1.0 },
        { x: 12.07724714, y: 1.0 },
        { x: 11.51262202, y: 1.0 },
        { x: 13.83044464, y: 1.0 },
        { x: 11.12445578, y: 1.0 },
        { x: 15.00004097, y: 1.0 },
        { x: 17.4453125, y: 1.0 },
        { x: 15.80193537, y: 1.0 },
        { x: 2.9269639, y: 0.0 },
        { x: 6.05441506, y: 0.0 },
        { x: 3.52601205, y: 0.0 },
        { x: 7.83319354, y: 0.0 },
        { x: 4.95959781, y: 0.0 },
        { x: 5.26498649, y: 0.0 },
        { x: 9.19267124, y: 0.0 },
        { x: 5.10032875, y: 0.0 },
        { x: 4.4596092, y: 0.0 },
        { x: 3.3636181, y: 0.0 },
        { x: 3.58350945, y: 0.0 },
        { x: 4.21980438, y: 0.0 },
        { x: 7.00634043, y: 0.0 },
        { x: 1.78056895, y: 0.0 },
        { x: 4.12428747, y: 0.0 },
        { x: 5.01040347, y: 0.0 },
        { x: 5.44887967, y: 0.0 },
        { x: 2.48619262, y: 0.0 },
        { x: 1.42746508, y: 0.0 },
        { x: 1.85395033, y: 0.0 },
        { x: 4.72708876, y: 0.0 },
        { x: 5.30355204, y: 0.0 },
        { x: 7.55910837, y: 0.0 },
        { x: 4.0093867, y: 0.0 },
        { x: 4.24379712, y: 0.0 },
        { x: 0.08308462, y: 0.0 },
        { x: 5.50348607, y: 0.0 },
        { x: 5.71319369, y: 0.0 },
        { x: 1.24762303, y: 0.0 },
        { x: 7.27987562, y: 0.0 },
        { x: 4.36898554, y: 0.0 },
        { x: 4.26293991, y: 0.0 },
        { x: 3.99160625, y: 0.0 },
        { x: 2.74201934, y: 0.0 },
        { x: 6.4868309, y: 0.0 },
        { x: 5.18134547, y: 0.0 },
        { x: 1.35958393, y: 0.0 },
        { x: 2.11425955, y: 0.0 },
        { x: 9.0892649, y: 0.0 },
        { x: 4.36015015, y: 0.0 }
      ],
      minX: -5,
      maxX: 20,
      beta: 1,
      alpha: 7,
      xLabel: "Hours studied",
      yLabel: "Probability of passing",
      alphaValues: _.range(0, 15),
      betaValues: [
        -8,
        -7,
        -6,
        -5,
        -4,
        -3,
        -2,
        -1,
        -0.8,
        -0.6,
        -0.4,
        -0.2,
        0,
        0.2,
        0.4,
        0.6,
        0.8,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8
      ]
    };
  }

  render() {
    const {
      data,
      minX,
      maxX,
      beta,
      alpha,
      xLabel,
      yLabel,
      alphaValues,
      betaValues
    } = this.state;
    return (
      <DefaultContainer>
        <LogisticRegressionChart
          data={data}
          maxX={maxX}
          minX={minX}
          beta={beta}
          alpha={alpha}
          xLabel={xLabel}
          yLabel={yLabel}
          disableIncorrect={true}
        />
        <ControllersContainer>
          <ControllerContainer>
            <Button
              onClick={() => this.updateBeta(previousValue(beta, betaValues))}
            >
              -
            </Button>
            <ValueContainer>
              <ControllerText m={10}>
                <FormattedMessage id="section.logisticRegressionExample.slope" />
                : {beta}
              </ControllerText>
            </ValueContainer>
            <Button
              onClick={() => this.updateBeta(nextValue(beta, betaValues))}
            >
              +
            </Button>
          </ControllerContainer>
          <ControllerContainer>
            <Button
              onClick={() =>
                this.updateAlpha(previousValue(alpha, alphaValues))
              }
            >
              -
            </Button>
            <ValueContainer>
              <ControllerText m={10}>
                <FormattedMessage id="section.logisticRegressionExample.intercept" />
                : {alpha}
              </ControllerText>
            </ValueContainer>
            <Button
              onClick={() => this.updateAlpha(nextValue(alpha, alphaValues))}
            >
              +
            </Button>
          </ControllerContainer>
        </ControllersContainer>
      </DefaultContainer>
    );
  }

  updateBeta(newValue) {
    this.setState({
      beta: newValue
    });
  }

  updateAlpha(newValue) {
    this.setState({
      alpha: newValue
    });
  }
}
