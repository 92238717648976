import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";
import Box from "../Box";

import TextF1 from "../TextF1";
import TextF2 from "../TextF2";
import TextF3 from "../TextF3";
import TextF5 from "../TextF5";
import TextF6 from "../TextF6";
import TextF7 from "../TextF7";

import DefaultContainer from "./DefaultContainer";

import SelfDrivingCarUrl from "../../images/self-driving-car.svg";

import ContentRecommendationUrl from "../../images/content-recommendation.svg";

import ImageAndVideoProcessingUrl from "../../images/image-and-video-processing.svg";

const RichText = styled(TextF7)`
  a {
    color: #9c329c;
    &:hover {
      text-decoration: none;
    }
  }
`;

const StyledBox = styled(Box)`
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.08);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
  .inner {
    padding: 2.5rem 1.5rem 3.5rem;
  }
  @media screen and (min-width: 72em) {
    .inner {
      padding: 2.5rem 2.5em 3.5rem;
    }
    margin-left: -2.5em;
    margin-right: -2.5em;
  }
`;

const IllustrationWrapper = styled(Box)`
  position: relative;
  img {
    position: relative;
    width: 100%;
    height: auto;
  }
`;

const Illustration = props => {
  switch (props.motive) {
    case "self-driving-cars":
      return <img src={SelfDrivingCarUrl} />;
    case "image-and-video-processing":
      return <img src={ImageAndVideoProcessingUrl} />;
    case "content-recommendation":
      return <img src={ContentRecommendationUrl} />;
    default:
      return null;
  }
};

const HighlightContent = props => {
  const { heading, description, svg } = props;
  return (
    <DefaultContainer>
      <StyledBox>
        <IllustrationWrapper>
          <Illustration motive={svg} />
        </IllustrationWrapper>
        <div className="inner">
          <TextF5 mt={0} mb={3}>
            {heading}
          </TextF5>
          <RichText m={0} dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </StyledBox>
    </DefaultContainer>
  );
};

export default HighlightContent;
