import React from "react";
import TextF5 from "../TextF5";
import { MediumContainer } from "../Grid";
import styled from "styled-components";
import Box from "../Box";
import { themeGet } from "styled-system";

const StyledBox = styled(Box)`
  margin-bottom: 6rem;
  margin-top: 2rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  /* overflow: scroll; */
  -webkit-overflow-scrolling: touch;
  @media screen and (min-width: 38em) {
    margin-left: 0;
    margin-right: 0;
  }
  table {
    margin: 2rem 0;
    width: 100%;
    border: none;
    font-size: 1rem;
    font-family: ${themeGet("fonts.TTNormsProRegular")};
    font-weight: normal;
    border-collapse: collapse;
    margin-left: 1.5rem;
    @media screen and (min-width: 38em) {
      margin-left: 0;
    }
    th,
    td {
      border: none;
      text-align: left;
      padding: 1rem 1rem;
    }
    tr:nth-child(even) {
      background-color: rgba(235, 233, 239, 0);
    }
    tr:nth-child(odd) {
      background-color: rgba(235, 233, 239, 1);
    }
    tr:first-child {
      font-family: ${themeGet("fonts.TTNormsProBold")};
      font-weight: normal;
    }
    tr {
      td,
      th {
        border: 1px solid #29264c;
      }
      ${"" /* td:first-child,
      th:first-child {
        border-width: 0;
      } */};
    }
  }
`;

const Table = props => {
  const { children, ...rest } = props;
  return (
    <MediumContainer>
      <StyledBox>
        <table border="0" cellSpacing="0" cellPadding="0">
          {children}
        </table>
      </StyledBox>
    </MediumContainer>
  );
};

export default Table;
