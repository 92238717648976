import React, { Component } from "react";
import styled from "styled-components";

const GraphConstants = {
  dataPointRadius: 4,
  dataPointFill: "#5BC4E7",
  dataPointNotActiveFill: "#CCE0E6",
  gridStroke: "#CDCCE2",
  gridStrokeOpacity: "0.25",
  gridStrokeWidth: "1px",
  curveStroke: "#A3A1D1",
  curveStrokeWidth: "3px",
  axisLineStroke: "#A3A1D1",
  axisLineWidth: "2px",
  axisTextSize: "12px",
  axisLabelSize: "16px",
  axistFontFamily: '"tt-norms-pro-regular","sans-serif"',
  axisTextHorizontalAlign: "middle",
  axisTextVerticalAlign: "center",
  axisTextWeight: "bold",
  axisTextFill: "#000",
  axistLabelOpacity: "0.5"
};

const GraphFunctions = {
  sigmoid: (x, alpha, beta) => 1.0 / (1 + Math.exp(-beta * (x - alpha))),
  step: x => (x >= 0 ? 1 : 0),
  relu: x => (x < 0 ? 0 : x),
  linear: x => x,
  linearregression: (x, alpha, beta) => alpha * x + beta
};

const SvgContainer = styled.div`
  width: ${props => props.width};
  overflow-x: auto;
  overflow-y: hidden;
`;

const AxisText = styled.text`
  font-size: ${GraphConstants.axisTextSize};
  text-anchor: ${GraphConstants.axisTextHorizontalAlign};
  fill: ${GraphConstants.axisTextFill};
  dominant-baseline: ${GraphConstants.axisTextVerticalAlign};
  font-family: ${GraphConstants.axistFontFamily};
`;

const AxisLabel = styled(AxisText)`
  font-family: ${GraphConstants.axistFontFamily};
  opacity: ${GraphConstants.axistLabelOpacity}
  font-size: ${GraphConstants.axisLabelSize}
`;

const AxisLine = styled.line`
  fill: none;
  stroke: ${GraphConstants.axisLineStroke};
  stroke-width: ${GraphConstants.axisLineWidth};
`;

const Curve = styled.path`
  fill: none;
  stroke: ${GraphConstants.curveStroke};
  stroke-width: ${GraphConstants.curveStrokeWidth};
`;

const DataPoint = styled.circle`
  fill: ${props =>
    !props.disableIncorrect || props.correct
      ? GraphConstants.dataPointFill
      : GraphConstants.dataPointNotActiveFill};
`;

const GridLine = styled.line`
  stroke-width: ${GraphConstants.gridStrokeWidth};
  stroke: ${GraphConstants.gridStroke};
  opacity: ${GraphConstants.gridStrokeOpacity};
`;

export {
  GraphConstants,
  GraphFunctions,
  SvgContainer,
  AxisLine,
  AxisText,
  AxisLabel,
  Curve,
  DataPoint,
  GridLine
};
