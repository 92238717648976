import React from "react";
import rehypeReact from "rehype-react";
import Helmet from "react-helmet";
import styled, { createGlobalStyle, injectGlobal } from "styled-components";
import { themeGet } from "styled-system";
import StyledLink from "../components/StyledLink";
import Header from "../components/Header";
import ScrollProgress from "../components/ScrollProgress";
import BannerIllustration from "../components/BannerIllustration";

import { injectIntl, FormattedMessage } from "react-intl";
import SectionCompletion from "../components/SectionCompletion";
import PropTypes from "prop-types";

import { SmallContainer, LargeContainer } from "../components/Grid";
import Box from "../components/Box";

import TextF1 from "../components/TextF1";
import TextF2 from "../components/TextF2";
import TextF5 from "../components/TextF5";
import TextF6 from "../components/TextF6";
import TextF8 from "../components/TextF8";

import MarkdownH1 from "../components/MarkdownComponents/MarkdownH1";
import MarkdownH2 from "../components/MarkdownComponents/MarkdownH2";
import MarkdownH3 from "../components/MarkdownComponents/MarkdownH3";
import MarkdownH4 from "../components/MarkdownComponents/MarkdownH4";
import MarkdownP from "../components/MarkdownComponents/MarkdownP";
import MarkdownLead from "../components/MarkdownComponents/MarkdownLead";
import MarkdownUl from "../components/MarkdownComponents/MarkdownUl";
import Note from "../components/MarkdownComponents/Note";
import ImportantBox from "../components/MarkdownComponents/Important";
import DummyExercise from "../components/MarkdownComponents/DummyExercise";
import KeyTerminology from "../components/MarkdownComponents/KeyTerminology";
import AdditionalResources from "../components/MarkdownComponents/AdditionalResources";
import PartSummary from "../components/MarkdownComponents/PartSummary";
import HighlightContent from "../components/MarkdownComponents/HighlightContent";
import ContentWithIcon from "../components/MarkdownComponents/ContentWithIcon";

import Table from "../components/MarkdownComponents/Table";
import CodeSnippet from "../components/MarkdownComponents/CodeSnippet";
import Example from "../components/MarkdownComponents/Example";
import Illustrations from "../components/MarkdownComponents/Illustrations";

import Exercise17 from "../components/MarkdownComponents/Exercise17";
import Exercise18 from "../components/MarkdownComponents/Exercise18";
import Exercise19 from "../components/MarkdownComponents/Exercise19";
import {
  Exercise23a,
  Exercise23b
} from "../components/MarkdownComponents/Exercise23";
import Exercise22 from "../components/MarkdownComponents/Exercise22";
import Exercise22b from "../components/MarkdownComponents/Exercise22b";

import LogisticRegressionExample from "../components/MarkdownComponents/LogisticRegressionExample";
import CNNExample from "../components/MarkdownComponents/CNNExample";
import Stats from "../components/Stats";
import Quiz from "../components/Quiz";
import Romanize from "../components/Romanize";
import ArrowUrl from "../images/arrow.svg";
import LinearRegressionStatic from "../components/MarkdownComponents/LinearRegressionStatic";

import withAuthentication from "../components/Session/withAuthentication";
import { connect } from "react-redux";

const HeroHeading = styled(TextF2)`
  margin-bottom: 0;
  margin-top: 4rem;
  letter-spacing: -1px;
  @media screen and (min-width: 38em) {
    margin-top: 6rem;
  }
  position: relative;
  span {
    color: #a3a1d1;
    @media screen and (min-width: 72em) {
      position: absolute;
      width: 70px;
      text-align: right;
      left: -90px;
    }
  }
`;

const StyledTextF1 = styled(TextF1)`
  color: #a3a1d1;
`;

const Footer = styled(Box)`
  background: #4844a3;
`;

const SectionLinkHeading = styled(TextF5)`
  color: white;
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
  display: block;
  width: 100%;
  margin: 0;
  transition: color 0.1s ease-in-out;
`;

const SectionLink = styled(StyledLink)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  padding: 1.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #29264c;
  .section-link-arrow {
    transition: all 0.2s ease;
  }
  :hover {
    ${SectionLinkHeading} {
      color: rgba(255, 255, 255, 0.5);
    }
    .section-link-arrow {
      transform: translateX(-10px);
    }
  }
`;

const LastSectionWrapper = styled(Box)`
  background: ${props =>
    props.part == 2
      ? "#8A8ED8"
      : props.part == 3
        ? "#50B9E8"
        : props.part == 4
          ? "#E15FBD"
          : props.part == 5
            ? "#00B5AA"
            : props.part == 6
              ? "#FFC07F"
              : "#19CA94"};
`;

const ContentWrapper = styled(Box)`
  margin-bottom: 6rem;
`;

const NextSectionHeading = styled(TextF8)`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${themeGet("fonts.TTNormsProRegular")};
  font-weight: normal;
`;

const ArrowSvg = styled(Box)`
  display: inline-block;
  height: 24px;
  width: 24px;
  background: red;
  background: url(${ArrowUrl});
  margin-left: 1rem;
  transition: 0.1s margin ease-in-out;
`;

function SectionTemplate({
  data,
  intl // this prop will be injected by the GraphQL query below.
}) {
  const { markdown, currentPart, allParts } = data; // data.markdown holds our post data
  const { frontmatter, htmlAst } = markdown;
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      note: Note,
      important: ImportantBox,
      table: Table,
      codesnippet: CodeSnippet,
      example: Example,
      illustrations: Illustrations,
      quiz: Quiz,
      highlightcontent: HighlightContent,
      contentwithicon: ContentWithIcon,
      "dummy-exercise": DummyExercise,
      "key-terminology": KeyTerminology,
      "additional-resources": AdditionalResources,
      "part-summary": PartSummary,
      lead: MarkdownLead,
      h1: MarkdownH1,
      h2: MarkdownH2,
      h3: MarkdownH3,
      h4: MarkdownH4,
      p: MarkdownP,
      ul: MarkdownUl,
      exercise17: Exercise17,
      exercise18: Exercise18,
      exercise19: Exercise19,
      exercise23a: Exercise23a,
      exercise23b: Exercise23b,
      exercise22: Exercise22,
      exercise22b: Exercise22b,
      linearreg: LinearRegressionStatic,
      "logistic-regression-example": LogisticRegressionExample,
      "cnn-example": CNNExample
    }
  }).Compiler;
  const breadcrumbs = [
    {
      title: intl.formatMessage({ id: "globals.courseOverview" }),
      path: intl.formatMessage({ id: "routes.LANDING" })
    },
    {
      title: currentPart.frontmatter.title,
      path: currentPart.frontmatter.path
    },
    {
      title: frontmatter.title,
      path: frontmatter.path
    }
  ];

  let SectionFooter;
  if (data.allRelatedSections.totalCount === frontmatter.section) {
    SectionFooter = (
      <div>
        <LastSectionWrapper part={currentPart.frontmatter.part + 1}>
          {currentPart.frontmatter.part == 6 ? (
            <Box py={4}>
              <LargeContainer>
                <Box py={5}>
                  <TextF2 color="white" textAlign="center" m={0}>
                    <FormattedMessage id="section.footerEndOfTheCourseTitle" />
                  </TextF2>
                  <TextF6 color="white" textAlign="center" mb={5}>
                    <FormattedMessage id="section.footerEndOfTheCourseText" />
                  </TextF6>
                  <Stats />
                  <TextF5 color="white" textAlign="center" mt={5}>
                    <a href={intl.formatMessage({ id: "routes.BUILDING_AI" })} target='_blank' rel='noopener noreferrer' style={{ color: '#FFFFFF' }}>
                      <FormattedMessage id="section.footerEndOfTheCourseBAI" />
                    </a>
                  </TextF5>
                </Box>
              </LargeContainer>
            </Box>
          ) : (
            <Box bg="rgba(0,0,0,0.15)" py={4}>
              <LargeContainer>
                <Box
                  display="flex"
                  flexWrap={["wrap", "no-wrap"]}
                  justifyContent={["center", "center", "space-between"]}
                  alignItems="center"
                >
                  <TextF5
                    textAlign={["center", "center", "left"]}
                    color="white"
                    mt={0}
                    mb={[3, 3, 0]}
                  >
                    <FormattedMessage
                      id="section.footerEndOfTheChapter"
                      values={{ chapter: frontmatter.part }}
                    />
                  </TextF5>
                  <Stats small />

                </Box>
              </LargeContainer>
            </Box>
          )}
        </LastSectionWrapper>

        {currentPart.frontmatter.part !== 6 &&
          data.allParts.edges
            .filter(
              ({
                node: {
                  frontmatter: { part }
                }
              }) => part === currentPart.frontmatter.part + 1
            )
            .map(({ node: { frontmatter } }) => (
              <div key={frontmatter.path}>
                <BannerIllustration
                  part={frontmatter.part}
                  subHeading={intl.formatMessage({ id: "section.nextChapter" })}
                  start={intl.formatMessage({ id: "section.start" })}
                  heading={frontmatter.title}
                  partLink={frontmatter.path}
                />
              </div>
            ))}
      </div>
    );
  } else {
    SectionFooter = (
      <SmallContainer>
        <Box py={5}>
          <NextSectionHeading>
            <FormattedMessage id="section.nextSection" />
          </NextSectionHeading>
          {data.allRelatedSections.edges.map(({ node }) => {
            if (node.frontmatter.section === frontmatter.section + 1) {
              return (
                <SectionLink
                  key={node.frontmatter.path}
                  to={node.frontmatter.path}
                >
                  <SectionLinkHeading>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <div>
                        <Romanize num={node.frontmatter.section} />{" "}
                        {node.frontmatter.title}
                      </div>
                      <div className="section-link-arrow">→</div>
                    </Box>
                  </SectionLinkHeading>
                </SectionLink>
              );
            }
          })}
        </Box>
      </SmallContainer>
    );
  }

  return (
    <div>
      <Helmet title={`${frontmatter.title}`}>
        <base target="_blank" />
      </Helmet>
      <Header breadcrumbs={breadcrumbs} />
      <ScrollProgress />
      <BannerIllustration part={frontmatter.part} section />
      <SmallContainer>
        <HeroHeading>
          <Romanize num={frontmatter.section} /> {frontmatter.title}
        </HeroHeading>
      </SmallContainer>
      <ContentWrapper>{renderAst(htmlAst)}</ContentWrapper>
      <Footer mt={[0]}>{SectionFooter}</Footer>
    </div>
  );
}

export default injectIntl(SectionTemplate);

export const pageQuery = graphql`
  query sectionByPath($part: Int!, $path: String!, $lang: String) {
    markdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        path
        title
        section
        part
        lang
      }
    }
    allRelatedSections: allMarkdownRemark(
      sort: { fields: [frontmatter___section], order: ASC }
      filter: {
        frontmatter: {
          part: { eq: $part }
          type: { eq: "section" }
          lang: { eq: $lang }
        }
      }
    ) {
      totalCount
      edges {
        node {
          htmlAst
          excerpt
          frontmatter {
            path
            title
            part
            type
            lang
            section
          }
        }
      }
    }
    allParts: allMarkdownRemark(
      sort: { fields: [frontmatter___part], order: ASC }
      filter: { frontmatter: { type: { eq: "part" }, lang: { eq: $lang } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            section
            part
            lang
            bannerImage {
              publicURL
            }
          }
        }
      }
    }
    currentPart: markdownRemark(
      frontmatter: {
        type: { eq: "part" }
        part: { eq: $part }
        lang: { eq: $lang }
      }
    ) {
      htmlAst
      frontmatter {
        path
        title
        part
        lang
        quote
        quoteAuthor
        bannerImage {
          publicURL
        }
      }
    }
    allSections: allMarkdownRemark(
      sort: { fields: [frontmatter___section], order: ASC }
      filter: { frontmatter: { type: { eq: "section" }, lang: { eq: $lang } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            path
            section
            part
            lang
          }
        }
      }
    }
    site: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
