import React from "react";
import styled from "styled-components";
import { themeGet } from "styled-system";

import Text from "../Text";
import TextF7 from "../TextF7";
import DefaultContainer from "./DefaultContainer";

//Certain greek letters are showing outside of the unicode-range when strong
//Therefore, the wrong font is loading
//No great way to pass the language key into this component (?)
//Short-term solution, get lang based on URL for Greek.

const StyledTextF7 = styled(TextF7)`
  margin-top: 0;
  margin-bottom: 2rem;
  strong {
    font-family: ${themeGet("fonts.alkesSemibold")};
    font-weight: 400;
  }
  a {
    color: #9c329c;
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }
`;

const MarkdownP = props => {
  const { ...rest } = props;
  return (
    <DefaultContainer>
      <StyledTextF7 {...rest} />
    </DefaultContainer>
  );
};

export default MarkdownP;
